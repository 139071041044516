import React from 'react'
import DataTable from 'react-data-table-component';
import { useLocation, useNavigate } from 'react-router';
import { FaCircleCheck } from "react-icons/fa6";
import { IoIosWarning } from "react-icons/io";
import { PiWarningDiamondFill } from "react-icons/pi";
import { Container } from 'reactstrap';
import { SlArrowLeft } from "react-icons/sl";
function ModuleTable() {
    const location = useLocation();
    const navigate = useNavigate();
  const { cameras, module } = location.state || {};
  const columns = [
    {
      name: "Camera Name",
      minWidth: "190px",
      selector: (row) => row.cameraName, // Access the Camera ID
      sortable: true,
    },
    {
      name: "Sub Area",
      selector: (row) => row.subArea, // Access the Sub Area
      sortable: true,
    },
    // {
    //   name: "Area Name",
    //   selector: (row) => row.areaName, // Access the Area Name
    //   sortable: true,
    // },
    // {
    //   name: "Area Owner",
    //   selector: (row) => row.areaOwner, // Access the Area Owner
    //   sortable: true,
    // },
    {
      name: "Compliance %",
      selector: (row) => {
        return(
            <div 
            style={{width:'70px',backgroundColor:`${row.compliancePercentage >= 80 ? '#E5F5E4' : (row.compliancePercentage<80 && row.compliancePercentage>=60) ? '#DEF2FA' : '#FDE3E3'}`}}
            className={`d-flex justify-content-between align-items-center px-2 rounded-5`}>
                {
                    row.compliancePercentage >= 80 ?
                    <FaCircleCheck style={{color:'#54BA4A', fontSize:'14px'}} /> : (row.compliancePercentage<80 && row.compliancePercentage>=60) ?
                    <PiWarningDiamondFill style={{color:'#26A5DC', fontSize:'20px'}}/> : <IoIosWarning style={{color:'#EF4343', fontSize:'20px'}}/>
                }
                <p className='m-0 p-0'>{row.compliancePercentage}%</p>
            </div>
        )
      },
      sortable: true,
    },
    {
      name: "Compliance",
      selector: (row) => `${row.compliance}`, // Add % for Compliance Percentage
      sortable: true,
    },
    {
      name: "Non-Compliance",
      selector: (row) => row.nonCompliance, // Access Non-Compliance value
      sortable: true,
    },
    {
      name: "Frame",
      selector: (row) => row.frames, // Access Frame value
      sortable: true,
    },
    {
      name: "Event",
      selector: (row) => row.events, // Access Event value
      sortable: true,
    },
  ];

  const customStyles = {
    table: {
      style: {
        // borderRadius: "24px", // Rounds the borders of the table
        overflow: "hidden", // Ensures content stays within rounded corners
        borderTopLeftRadius: "24px",
        borderTopRightRadius: "24px",
      },
    },
    header: {
      style: {
        borderTopLeftRadius: "24px",
        borderTopRightRadius: "24px",
        backgroundColor: "#f4f4f4",
      },
    },
    rows: {
      style: {
        borderBottom: "1px solid #ddd",
      },
    },
    pagination: {
        style: {
          borderBottomLeftRadius: "24px", // Rounds the bottom-left corner of pagination
          borderBottomRightRadius: "24px", // Rounds the bottom-right corner of pagination
        },
      },
  };
  return (
    <Container fluid={true}>
    <div onClick={()=> navigate(-1)} style={{paddingBlock:'16px', cursor:'pointer'}} className='d-flex align-items-center gap-2'>
    <SlArrowLeft style={{fontSize:'15px', color:'#175FA4'}} />
    <p style={{fontSize:'20px', color:'#175FA4'}} className='m-0 p-0'>Back</p>
    </div>
    <div style={{paddingBlock:'16px'}}>
    <p style={{fontWeight:'400', fontSize:'20px'}} className='m-0 p-0'>Module: <span style={{fontWeight:'500'}}>{module}</span></p>
    </div>
         <DataTable
        // title={`${module} Module`}
        columns={columns}
        data={cameras || []} // Pass the cameras array as data
        pagination
        highlightOnHover
        customStyles={customStyles}
      />
    </Container>
  )
}

export default ModuleTable