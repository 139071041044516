import React, { useState } from "react";
import liveAlertContext from "./LiveAlert";  // Ensure you import the correct context file

const LiveAlertDataProvider = (props) => {
    const [liveAlertData, setLiveAlertData] = useState([]); 
    const [totalLiveAlerts, settotalLiveAlerts] = useState({
        accepted:'',
        rejected:''
    }); 

    return (
        <liveAlertContext.Provider
            value={{
                liveAlertData,
                setLiveAlertData,
                totalLiveAlerts,
                settotalLiveAlerts
            }}
        >
            {props.children}
        </liveAlertContext.Provider>
    );
};

export default LiveAlertDataProvider;
