// import React, { useEffect, useState } from 'react';
// import { Card, Col, Container, Row } from 'reactstrap';
// import  Loader1  from '../../../CommonElements/Spinner/loader';
// import AreaService from '../../../api/areaService';
// import { getCurrentWeekWithYear } from '../../../utils/currentWeekWithYear';
// import DetailCardSubarea from './Components/DetailsCard/DetailCardSubarea';

// const SubAreaAnalysis = () => {
//   const currentWeekk = getCurrentWeekWithYear()
//   const [loader, setloader] = useState(true);
//   const [allData, setAllData] = useState([]);
//   const [areaData, setareaData] = useState([])
//   const processData = (data) => {
//     // Process sub-areas to calculate total Compliance and Violations
//     data.forEach((area) => {
//       const subAreaCounts = {};
//       const newSubAreas = [];

//       area.SubAreas.forEach((sub) => {
//         if (subAreaCounts[sub.SubAreaName]) {
//           subAreaCounts[sub.SubAreaName].count += 1;
//           subAreaCounts[sub.SubAreaName].compliance += sub.Compliance;
//           subAreaCounts[sub.SubAreaName].violations += sub.Violations;
//         } else {
//           subAreaCounts[sub.SubAreaName] = {
//             count: 1,
//             compliance: sub.Compliance,
//             violations: sub.Violations,
//           };
//         }
//       });

//       Object.keys(subAreaCounts).forEach((subAreaName) => {
//         const subAreaData = subAreaCounts[subAreaName];

//         newSubAreas.push({
//           SubAreaName: subAreaName,
//           Cameras: subAreaData.count,
//           Compliance: subAreaData.compliance, // Sum of Compliance
//           Violations: subAreaData.violations, // Sum of Violations
//         });
//       });

//       area.SubAreas = newSubAreas;
//     });

//     // Adjusted logic for area-level Compliance
//     data.forEach((area) => {
//       // Calculate total Compliance and Violations at the area level
//       const totalSubAreaCompliance = area.SubAreas.reduce((acc, subArea) => acc + (subArea.Compliance || 0), 0);
//       const totalSubAreaViolations = area.SubAreas.reduce((acc, subArea) => acc + (subArea.Violations || 0), 0);

//       // Calculate overall area compliance using the specified formula
//       function complianceFunc(compliance, violation) {
//         if (violation === 0) {
//           return 100
//         }
//         return (compliance / (compliance + violation)) * 100
//       }
//       const areaCompliance = complianceFunc(totalSubAreaCompliance, totalSubAreaViolations)
//       //  totalSubAreaCompliance + totalSubAreaViolations > 0
//       //   ? (totalSubAreaCompliance / (totalSubAreaCompliance + totalSubAreaViolations)) * 100
//       //   : 0; // Handle case when denominator is 0


//       area.Compliance = Math.round(areaCompliance); // Add Compliance field at the area level

//       // Total cameras calculation remains unchanged
//       const totalCameras = area.SubAreas.reduce((acc, subArea) => acc + subArea.Cameras, 0);
//       area.Cameras = totalCameras; // Add Cameras field at the area level
//     });

//     return data;
//   };
//   useEffect(() => {
//     const payload = {
//       // safety_area: area_id ? [area_id] : filters.areas ,
//       // shift: filters.shifts ,
//       // start_date: filters.starting!=='' ? formatDate(filters.starting) : filters.date=='' ? '' : formatDate(filters.date),
//       // end_date: filters.ending!=='' ? formatDate(filters.ending) : filters.date=='' ? '' : formatDate(filters.date),
//       week: currentWeekk,

//     };
//     getAiReportsCardsFunc(payload)


//   }, [])

//   // Example Usage inside useEffect

//   async function getAiReportsCardsFunc(payload) {
//     setloader(true)
//     const res = await AreaService.getAiReportsCards(payload);

//     let updatedData = res?.data;
//     let mappedAreas = [];

//     updatedData = updatedData?.sort((a, b) => {
//       const numA = parseInt(a.AreaName.split('-')[1], 10);
//       const numB = parseInt(b.AreaName.split('-')[1], 10);
//       return numA - numB;
//     });

//     // Fetch allarea if not already populated


//     const areaRes = await AreaService.getAreaUserTickets(
//       JSON.parse(localStorage.getItem("userData"))?.id
//     );
//     if (areaRes) {
//       setloader(false)
//     }
//     setareaData(areaRes.data.data.areas)
//     mappedAreas =
//       areaRes?.data?.data?.areas.map((area) => ({
//         ...area,
//         label: `${area.area_name}, ${area.area_owner}`,
//         disabled: !area.active,
//       })) || [];

//     updatedData = updatedData.filter((data) =>
//       mappedAreas?.some((a) => data.AreaName === a.area_name && a.active)
//     );

//     const updateddData = processData(updatedData); // Process the data
//     console.log('Updated Data:', updateddData); // Check if the data is correct

//     setAllData(updateddData); // Set the updated data to state
//     // setAllData(updatedData);
//   }

//   return (
//     <>
//       <br />
//       <Container fluid={true}>
//         <h5>Area Analysis</h5>
//         {
//           loader ? <Loader1 />
//             :
//             (

//               <Row>
//                 {allData.map((items) => (
//                   <Col xxl='3' xl='4' lg='6' md='6' >
//                     <DetailCardSubarea allData={allData} setAllData={setAllData} items={items} areaData={areaData} />
//                   </Col>
//                 ))}
//               </Row>
//             )
//         }
//       </Container>
//     </>
//   );
// }

// export default SubAreaAnalysis;


import React, { useEffect, useState } from 'react';
import { Camera, Layers } from 'react-feather';
import './Style/areaStyles.css'
import { errorToast,getWeek } from '../../../_helper/helper';
import AreaService from '../../../api/areaService';
import Loader1 from '../../../CommonElements/Spinner/loader'

// const members = [
//   { id: "AO-1", name: "Sub Area 1", camera: 1, compliance: 0 },
//   { id: "AO-2", name: "Sub Area 2", camera: 2, compliance: 90 },
//   { id: "AO-6", name: "Sub Area 3", camera: 19, compliance: 78 },
//   { id: "AO-7", name: "Sub Area 4", camera: 7, compliance: 100 },
//   { id: "AO-8", name: "Sub Area 5", camera: 1, compliance: 36 },
//   { id: "AO-9", name: "Sub Area 6", camera: 2, compliance: 100 },
//   { id: "AO-10", name: "Sub Area 7", camera: 4, compliance: 58 },
//   { id: "AO-13", name: "Sub Area 8", camera: 5, compliance: 56 },
//   { id: "AO-14", name: "Sub Area 9", camera: 4, compliance: 60 }
// ];
const members = [
  { id: "AO-1", name: "waste window area", camera: 1, compliance: 0,modules:['Helmet'],owner:"Adil Sultan", alerts:10},
  { id: "AO-2", name: "Ref workshop", camera: 2, compliance: 90,modules:['Helmet', 'Emergency Exit' ],owner:"Muhammad Aftab", alerts:10 },
  { id: "AO-6", name: "DRY Store 1, 2", camera: 19, compliance: 78 ,modules:['Helmet','Vest','MMHE'  ],owner:"Meraj Khalid", alerts:10}, 
  { id: "AO-6", name: "chemical store", camera: 21, compliance: 72,modules:['Helmet','Vest'],owner:"Meraj Khalid" , alerts:10},
  { id: "AO-6", name: "docking stations", camera: 22, compliance: 76 ,modules:['Helmet','Vest','MMHE'],owner:"Meraj Khalid", alerts:10},
  { id: "AO-7", name: "Production Floor", camera: 7, compliance: 100,modules:[ 'Emergency Exit','Machine Guard'] ,owner:"Moazzam Ali", alerts:10},
  { id: "AO-8", name: "Air compressor", camera: 8, compliance: 92 ,modules:['Helmet'],owner:"M.Shahbaz", alerts:10},
  { id: "AO-9", name: "Galleries", camera: 9, compliance: 85,modules:[ 'Emergency Exit' ],owner:"M.Wasi" , alerts:10},
  { id: "AO-10", name: "LT room", camera: 10, compliance: 88 ,modules:['Helmet'],owner:"Nazir Ahmed", alerts:10},
  { id: "AO-10", name: "ammonia soft starter room", camera: 11, compliance: 84,modules:['Helmet'] ,owner:"Nazir Ahmed", alerts:10},
  { id: "AO-13", name: "Pump House", camera: 12, compliance: 89,modules:['Helmet'  ],owner:"Shahbaz", alerts:10 },
  { id: "AO-13", name: "Biomass Boiler (including fuel storage shed)", camera: 13, compliance: 87,modules:['Helmet' ],owner:"Shahbaz" , alerts:10},
  { id: "AO-14", name: "Cold Store 1&2", camera: 14, compliance: 94,modules:['Helmet' ,'MMHE' ],owner:"Sheraz" , alerts:10}, 
]; 
export default function AreaAnalysisCards() {
  const [memberss, setmemberss] = useState([]);
  const [loading, setloading] = useState(true);
  const now = new Date();
  const year = now.getFullYear();
  const week = getWeek(now);
  const maxWeek = `${year}-W${String(week).padStart(2, "0")}`;

  const setDataFormat=(data)=>{ 
   try {
    const correctFormat = data.flatMap((item,key) => { 
      const groupedSubareas = item.SubAreas.reduce((acc, subarea) => {  
        if (!acc[subarea.SubAreaName]) {
          acc[subarea.SubAreaName] = { 
            totalCompliance: 0, 
            totalViolations: 0, 
            subareaName: subarea.SubAreaName,
            violations: subarea.Violations , 
            cameraCount: 0
          };
        } 
        acc[subarea.SubAreaName].totalCompliance += subarea.Compliance;
        acc[subarea.SubAreaName].totalViolations += subarea.Violations; 
        acc[subarea.SubAreaName].cameraCount += 1;
        return acc;
      }, {});
     
      return Object.values(groupedSubareas).map((subarea) => {
        const total = subarea.totalCompliance + subarea.totalViolations;
        const calculated = total ? ((subarea.totalCompliance / total) * 100).toFixed(0) : 0;
        const violationss= subarea.totalViolations 
        console.log(groupedSubareas)
        return {
          areaName: item.AreaName,
          areaOwner: item.AreaOwner,
          totalCameras: subarea.cameraCount,
          subareaName: subarea.subareaName,
          alerts: violationss,
          calculated,
          compliance: calculated > 0 ? calculated : subarea.violations == 0 ? 100 : 0
        };
      });
    }); 
    return correctFormat
   } catch (error) {
    errorToast('Error found in data format');
    console.log(error)
   }
  }
const getSubareasDetails=async()=>{
  try {
    const payload={
      "safety_area": [],
      "shift": [],
      "start_date": "",
      "end_date": "",
      "week": maxWeek,
      "month": ""
  }
    const res=await AreaService.getAiReportsCards(payload);
    if(res.status==200){  
      const finalData=setDataFormat(res?.data);
    console.log(finalData)

      setmemberss(finalData);
      setloading(false)
    }
  } catch (error) {
    errorToast('Error while fetching data');
    console.log(error)
    return
  }
}
useEffect(() => {
  getSubareasDetails()
}, [])

  return (
    <div className="container-fluid py-5">
      <h4>Sub Area Analysis</h4>
      {loading?<Loader1/>:
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xxl-3 row-cols-2xl-4 g-4">
        {memberss.map((member, index) => {
          let className = '';
          let classNameForArea = '';
          if(member?.compliance < 50) {
            className = 'com-red-color'
            classNameForArea = 'bg-red-color'
          } else if(member?.compliance >= 50 && member?.compliance < 80) {
             className = 'com-yellow-color'
             classNameForArea = 'bg-yellow-color'
          } else {
            className = 'com-green-color'
            classNameForArea = 'bg-green-color'
          }
          return (<div key={index} className="col" data-aos="fade-up" data-aos-delay={index * 100}>
            <div className="card h-100 shadow-sm">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3" >
                   <div className='d-flex gap-2' style={{width: '85%'}}>
                    <Layers color='#1e67d6' size={20} style={{width: 'max-content'}} /> 
                    <div>
                   <h6 className="card-title m-0 p-0">{member.subareaName}</h6>
                   <p className='f-light m-0 p-0'>{member.areaOwner}</p>

                    </div>
                   </div> 
                  <span className={`badge align-self-start`}  style={{backgroundColor: '#1e67d6'}}>{member.areaName}</span>
                 
                </div>
                <hr />
                <div className='d-flex justify-content-between align-items-center pb-2'>
                <p className="card-text d-flex align-items-center gap-2 m-0">
                 {/* <Camera fill='#1e67d6e0' color='#1e67d6' size={20} /> */}
                 <Camera fill='#1e67d6' color='#fff' size={25} style={{ transition: '0.3s', hover: { fill: '#1e67d6', color: '#005bb5' } }} />
                  {member.totalCameras > 1 ? <div><span><b>{member.totalCameras}</b></span> <span>Cameras</span></div>  : <div><span><b>{member.totalCameras}</b></span> <span>Camera</span></div> } 
                </p>
                <p className='m-0 p-0'><b>{member.alerts}</b> Alerts</p>

                </div> 
                    <div className='d-flex'>
                     {members.map((name,key)=>(
                      <>
                         {name.name == member.subareaName ? name.modules.map((item,key)=>(
                           <p className='f-light ms-0 me-1 my-0'>{item} {key +1 == name?.modules.length ? null : '|'} </p>
                         )) :''}
                      </>
                     ))}
                    {/* {member?.modules?.map((name,key)=>(
                      <p className='f-light ms-0 me-1 my-0'>{name} {key +1 == member?.modules.length ? null : '|'} </p>
                    ))} */}
                      </div>  
                <div className="mt-3">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <span>Compliance</span>
                    {/* ${className} */}
                    <span className={`f-light fw-bold `}>{member.compliance}%</span>
                  </div>
                  <div className="progress" style={{ height: '10px' }}>
                    <div
                      className={`progress-bar progress-bar-striped progress-bar-animated ${classNameForArea}`}
                      role="progressbar"
                      style={{ width: `${member.compliance}%` }}
                      aria-valuenow={member.compliance}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>) 
        })}
      </div>
      }
    </div>
  );
}

// import React from 'react';
// import { Camera, Layers } from 'react-feather';

// // Restructured data to group sub-areas under main areas
// // const areas = [
// //   {
// //     id: "AO-1",
// //     name: "AO-1",
// //     subAreas: [
// //       { id: "1", name: "Sub Area 1", camera: 1, compliance: 0 },
// //       { id: "2", name: "Sub Area 2", camera: 2, compliance: 90 },
// //       { id: "3", name: "Sub Area 3", camera: 19, compliance: 78 },
// //     ]
// //   },
// //   {
// //     id: "AO-2",
// //     name: "AO-2",
// //     subAreas: [
// //       { id: "1", name: "Sub Area 4", camera: 7, compliance: 100 },
// //       { id: "2", name: "Sub Area 5", camera: 1, compliance: 36 },
// //       { id: "3", name: "Sub Area 6", camera: 2, compliance: 100 },
// //     ]
// //   },
// //   {
// //     id: "AO-3",
// //     name: "AO-3",
// //     subAreas: [
// //       { id: "1", name: "Sub Area 7", camera: 4, compliance: 58 },
// //       { id: "2", name: "Sub Area 8", camera: 5, compliance: 56 },
// //       { id: "3", name: "Sub Area 9", camera: 4, compliance: 60 },
// //     ]
// //   }
// // ];
// const areas = [
//   {
//     id: "AO-1",
//     name: "Adil Sultan",
//     subAreas: [
//       { id: "1", name: "Waste Window Area", camera: 1, compliance: 0 },
//     ],
//   },
//   {
//     id: "AO-2",
//     name: "Muhammad Aftab",
//     subAreas: [
//       { id: "1", name: "Ref Workshop", camera: 2, compliance: 90 },
//     ],
//   },
//   {
//     id: "AO-6",
//     name: "Meraj Khalid",
//     subAreas: [
//       { id: "1", name: "Dry Store 1", camera: 5, compliance: 85 },
//       { id: "2", name: "Dry Store 2", camera: 6, compliance: 78 },
//       { id: "3", name: "Chemical Store", camera: 4, compliance: 76 },
//       { id: "4", name: "Docking Stations", camera: 8, compliance: 82 },
//     ],
//   },
//   {
//     id: "AO-7",
//     name: "Moazzam Ali",
//     subAreas: [
//       { id: "1", name: "Production Floor", camera: 3, compliance: 88 },
//     ],
//   },
//   {
//     id: "AO-8",
//     name: "Muhammad Shahbaz",
//     subAreas: [
//       { id: "1", name: "Air Compressor", camera: 2, compliance: 93 },
//     ],
//   },
//   {
//     id: "AO-9",
//     name: "Muhammad Wasi",
//     subAreas: [
//       { id: "1", name: "Galleries", camera: 7, compliance: 75 },
//     ],
//   },
//   {
//     id: "AO-10",
//     name: "Nazir Ahmed",
//     subAreas: [
//       { id: "1", name: "LT Room", camera: 6, compliance: 70 },
//       { id: "2", name: "Ammonia Soft Starter Room", camera: 5, compliance: 65 },
//     ],
//   },
//   {
//     id: "AO-13",
//     name: "Shahbaz",
//     subAreas: [
//       { id: "1", name: "Pump House", camera: 4, compliance: 80 },
//       { id: "2", name: "Biomass Boiler (including fuel storage shed)", camera: 3, compliance: 85 },
//     ],
//   },
//   {
//     id: "AO-14",
//     name: "Sheraz",
//     subAreas: [
//       { id: "1", name: "Cold Store 1", camera: 8, compliance: 92 },
//       { id: "2", name: "Cold Store 2", camera: 9, compliance: 94 },
//     ],
//   },
// ];

// export default function AreaAnalysisCards() {
//   return (
//     <div className="container-fluid py-5">
//       {areas.map((area) => (
//         <div key={area.id} className="mb-5">
//           <h4 className="mb-4">{area.name} Sub Areas</h4>
//           <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
//             {area.subAreas.map((subArea, index) => (
//               <div key={subArea.id} className="col" data-aos="fade-up" data-aos-delay={index * 100}>
//                 <div className="card h-100 shadow-sm">
//                   <div className="card-body">
//                     <div className="d-flex justify-content-between align-items-center mb-3">
//                       <div className='d-flex gap-2'>
//                         <Layers size={20} /> 
//                         <h6 className="card-title">{subArea.name}</h6>
//                       </div> 
//                       <span className="badge bg-secondary">{subArea.id}</span>
//                     </div>
//                     <p className="card-text d-flex align-items-center gap-2">
//                       <Camera size={20} /> 
//                       {subArea.camera > 1 ? (
//                         <div>
//                           <span><b>{subArea.camera}</b></span> <span>Cameras</span>
//                         </div>
//                       ) : (
//                         <div>
//                           <span><b>{subArea.camera}</b></span> <span>Camera</span>
//                         </div>
//                       )}
//                     </p>
//                     <div className="mt-3">
//                       <div className="d-flex justify-content-between align-items-center mb-1">
//                         <span>Compliance</span>
//                         <span className="fw-bold">{subArea.compliance}%</span>
//                       </div>
//                       <div className="progress" style={{ height: '10px' }}>
//                         <div
//                           className="progress-bar progress-bar-striped progress-bar-animated"
//                           role="progressbar"
//                           style={{ width: `${subArea.compliance}%` }}
//                           aria-valuenow={subArea.compliance}
//                           aria-valuemin={0}
//                           aria-valuemax={100}
//                         ></div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// }





