export const areaaa = [
    {
      AreaName: "AO-1",
      AreaOwner: "Adil",
      Compliance : 84,SubAreas: [ 
        {
          SubAreaName: "Roof Tops (Palletizing)",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF- SA1 - RT - 1 - 4233 - 213",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "No",
          MachineGuard: "No",
          Forklift: "No",
        },
        {
          SubAreaName: "Palletizing 1,2 & 3",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF- SA1 - P- 1 - NVR - IP",
          Picture: "N/A",
          Helmet: "No",
          Vest: "No",
          EmergencyExit: "Yes",
          MachineGuard: "No",
          Forklift: "No",
        },
        {
          SubAreaName: "Palletizing Office",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF- SA1 - PO - 1 - NVR - IP",
          Picture: "N/A",
          Helmet: "No",
          Vest: "No",
          EmergencyExit: "Yes",
          MachineGuard: "No",
          Forklift: "No",
        },
        {
          SubAreaName: "Palletizing Corridor",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF- SA1 - PC - 1 - NVR - IP",
          Picture: "N/A",
          Helmet: "No",
          Vest: "No",
          EmergencyExit: "Yes",
          MachineGuard: "No",
          Forklift: "No",
        },
        {
          SubAreaName: "Waste Window Area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF- SA1 - WWA - 1 - NVR - IP",
          Picture: "N/A",
          Helmet: "No",
          Vest: "Yes",
          EmergencyExit: "Yes",
          MachineGuard: "No",
          Forklift: "Yes",
        },
      ],
    },
    {
      AreaName: "AO-2",
      AreaOwner: "Aftab",
      Compliance : 95,
      SubAreas: [
        {
          SubAreaName: "Roof Tops (Service Building)",
          CameraIP: "192.168.10.211",
          OutdoorIndoor: "Outdoor",
          NVR: "DHI-NVR5",
          CameraID: "ICF- SA2- RT-SB -1- 5- 211",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "No",
          MachineGuard: "No",
          Forklift: "No",
        },
        {
          SubAreaName: "Roof Tops (Service Building)",
          CameraIP: "192.168.10.215",
          OutdoorIndoor: "Outdoor",
          NVR: "DHI-NVR5",
          CameraID: "ICF- SA2- RT-SB -2- 5 - 215",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "No",
          MachineGuard: "No",
          Forklift: "No",
        },
        {
          SubAreaName: "Roof Tops (Service Building)",
          CameraIP: "192.168.10.216",
          OutdoorIndoor: "Outdoor",
          NVR: "DHI-NVR5",
          CameraID: "ICF- SA2- RT-SB -3- 5 -216",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "No",
          MachineGuard: "No",
          Forklift: "No",
        },
        {
          SubAreaName: "Roof Tops (Service Building)",
          CameraIP: "192.168.10.217",
          OutdoorIndoor: "Outdoor",
          NVR: "DHI-NVR5",
          CameraID: "ICF- SA2- RT-SB -4- 4232 -217",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "No",
          MachineGuard: "No",
          Forklift: "No",
        },
        {
          SubAreaName: "Roof Tops (Service Building)",
          CameraIP: "192.168.10.218",
          OutdoorIndoor: "Outdoor",
          NVR: "DHI-NVR5",
          CameraID: "ICF- SA2-  RT-SB -5-5-218",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "Yes",
          MachineGuard: "Yes",
          Forklift: "No",
        },
        {
          SubAreaName: "Roof Tops (Service Building)",
          CameraID: "ICF-SA2-RT-SB-6-5-219",
          CameraIP: "192.168.10.219",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "Yes",
          MachineGuard: "Yes",
          Forklift: "No",
        },
        {
          SubAreaName: "Roof Tops (Service Building)",
          CameraID: "ICF-SA2-RT-SB-7-5-227",
          CameraIP: "192.168.10.227",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Roof Tops (Service Building)",
          CameraIP: "192.168.10.227",
          OutdoorIndoor: "Outdoor",
          NVR: "DHI-NVR5",
          CameraID: "ICF- SA2- RT-SB - 7- 5-227",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Ammonia Compressor room",
          CameraIP: "192.168.10.213-",
          OutdoorIndoor: "Indoor",
          NVR: "DHI-NVR 05",
          CameraID: "ICF- SA2- ACR -1- 05- 213",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "No",
          MachineGuard: "No",
          Forklift: "No",
        },
        {
          SubAreaName: "Ammonia Compressor room",
          CameraIP: "192.168.10.220",
          OutdoorIndoor: "Indoor",
          NVR: "DHI-NVR5",
          CameraID: "ICF- SA2- ACR - 2 - 5-220",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "Yes",
          MachineGuard: "Yes",
          Forklift: "No",
        },
        {
          SubAreaName: "Ammonia Compressor room",
          CameraIP: "192.168.10.221",
          OutdoorIndoor: "Indoor",
          NVR: "DHI-NVR5",
          CameraID: "ICF- SA2- ACR - 3 - 5-221",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "Yes",
          MachineGuard: "Yes",
          Forklift: "No",
        },
        {
          SubAreaName: "Ammonia Compressor room",
          CameraIP: "192.168.10.222",
          OutdoorIndoor: "Indoor",
          NVR: "DHI-NVR5",
          CameraID: "ICF- SA2- ACR - 4- 5-222",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "Yes",
          MachineGuard: "Yes",
          Forklift: "No",
        },
        {
          SubAreaName: "Ammonia Compressor room",
          CameraIP: "192.168.10.223",
          OutdoorIndoor: "Indoor",
          NVR: "DHI-NVR5",
          CameraID: "ICF- SA2- ACR - 5- 5-223",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "Yes",
          MachineGuard: "Yes",
          Forklift: "No",
        },
        {
          SubAreaName: "Ammonia Compressor room",
          CameraIP: "192.168.10.225",
          OutdoorIndoor: "Indoor",
          NVR: "DHI-NVR5",
          CameraID: "ICF- SA2- ACR - 6- 5-225",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "Yes",
          MachineGuard: "Yes",
          Forklift: "No",
        },
        {
          SubAreaName: "Ammonia Compressor room",
          CameraIP: "192.168.10.226",
          OutdoorIndoor: "Indoor",
          NVR: "DHI-NVR5",
          CameraID: "ICF- SA2- ACR -7- 5-226",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "Yes",
          MachineGuard: "Yes",
          Forklift: "No",
        },
  
        {
          SubAreaName: "Catch pit area",
          CameraIP: "192.168.10.224",
          OutdoorIndoor: "Outdoor",
          NVR: "DHI-NVR5",
          CameraID: "ICF- SA2- CPA - 1- 5-224",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "Yes",
          MachineGuard: "Yes",
          Forklift: "No",
        },
        {
          SubAreaName: "Catch pit area",
          CameraIP: "192.168.10.179",
          OutdoorIndoor: "Outdoor",
          NVR: "DHI-NVR3",
          CameraID: "ICF- SA2- CPA - 2-3-179",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "Yes",
          MachineGuard: "Yes",
          Forklift: "No",
        },
        {
          SubAreaName: "Catch pit area",
          CameraIP: "192.168.10.42",
          OutdoorIndoor: "Outdoor",
          NVR: "DHI-NVR3",
          CameraID: "ICF- SA2- CPA -3-3-42",
          Picture: "N/A",
          Helmet: "Yes",
          Vest: "Yes",
          EmergencyExit: "Yes",
          MachineGuard: "Yes",
          Forklift: "No",
        },
  
        {
          SubAreaName: "Ref workshop",
          CameraIP: "192.168.10.81",
          OutdoorIndoor: "Indoor",
          NVR: "DHI-NVR2",
          CameraID: "ICF- SA2- RW- 1-2-81",
          Picture: "N/A",
          Helmet: "No",
          Vest: "Yes",
          EmergencyExit: "Yes",
          MachineGuard: "No",
          Forklift: "Yes",
        },
        {
          SubAreaName: "Ref workshop",
          CameraIP: "192.168.9.52",
          OutdoorIndoor: "Indoor",
          NVR: "DHI-NVR4",
          CameraID: "ICF- SA2- RW- 2-4-52",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Ref Control Room",
          CameraIP: "192.168.10.79",
          OutdoorIndoor: "Indoor",
          NVR: "DHI-NVR2",
          CameraID: "ICF- SA2- RCR- 1-2-79",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Ref Control Room",
          CameraIP: "192.168.9.51",
          OutdoorIndoor: "Indoor",
          NVR: "DHI-NVR8",
          CameraID: "ICF- SA2- RCR- 2-8-51",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Ammonia CCR",
          CameraIP: "192.168.10.79",
          OutdoorIndoor: "Indoor",
          NVR: "DHI-NVR2",
          CameraID: "ICF- SA2- ACCR- 1-2-79",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Ammonia CCR",
          CameraIP: "192.168.10.51",
          OutdoorIndoor: "Indoor",
          NVR: "DHI-NVR4",
          CameraID: "ICF- SA2- ACCR- 2-4-51",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
      ],
    },
    {
      AreaName: "AO-3",
      AreaOwner: "Arslan",
      Compliance : 40,SubAreas: [
        {
          SubAreaName: "Void Area (Production, Mixing)",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-VA-PM-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Admin Building Roof Top",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-AB-RT-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "AHU Room above Canteen",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-AHUR-AC-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Main Asset scrap yard",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-MASY-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Motor / Panel scrap yard",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-M-PSY-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "R&D front side scrap yard",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-RnD-SY-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Contractor Workshops",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-CW-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "DP store area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-DP-AO-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Engineering Store",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-ES-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Safety Office",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-SO-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Safety Storage Area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-SAO-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Engineering Store Placement Yard",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-ES-PY-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Fabrication Workshop & Surrounding Area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-FW-S-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Lathe Machine Workshop",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-RT-P-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "MAMz Workshop",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA3-LMW-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
      ],
    },
    {
      AreaName: "AO-4",
      AreaOwner: "Ayesha Khaliq",
      Compliance : 90,SubAreas: [
        {
          SubAreaName: "Roof Tops (Cone Baking)",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA4-RT-CB-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Cone Baking",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA4-CB-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Mixing",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA4-MX-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "LI Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA4-LIR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Aging Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA4-AR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Chocolate Plant",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA4-CP-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Mixing Pits",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA4-MXP-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Oil/Glucose Decanting Area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA4-O-GDA-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Sauce Plant",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA4-SP-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Chilled Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA4-CR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Day Store Area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA4-DAO-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Mixing Control Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA4-MCR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Tank Form",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA4-TF-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
      ],
    },
    {
      AreaName: "AO-5",
      AreaOwner: "Dr. Amjad",
      Compliance : 78,SubAreas: [
        {
          SubAreaName: "OHC",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA5-OHC-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Medical Roof Top",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA5-MRP-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
      ],
    },
    {
      AreaName: "AO-6",
      AreaOwner: "Meraj",
      Compliance : 85,SubAreas: [
        {
          SubAreaName: "Roof Tops (Dry Store)",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA6-RT-DS-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Roof Tops (Pulp Store)",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA6-RT-PS-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Scrap Yard (Packmat area/drums)",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA6-SY-PAD-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Dry Store 1, 2",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA6-DS-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Chemical Store",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA6-ChemS-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Dry Store Driver Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA6-DSDR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Docking Stations",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA6-DockS-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Washrooms",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA6-WashR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Pulp Store",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA6-PulpS-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Hot Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA6-HotR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Flavour Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA6-FlavrR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Pallet Washing Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA6-PWR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
      ],
    },
    {
      AreaName: "AO-7",
      AreaOwner: "Moazzam Ali",
      Compliance : 87,SubAreas: [
        {
          SubAreaName: "Machine Parts Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA7-MPR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Ultra Clean",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA7-UC-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Production Floor",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA7-PF-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Production Offices",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA7-PO-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "TPM Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA7-TPM-R-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Day Store",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA7-DS-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Parts Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA7-PartsR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Room 10",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA7-Room-10-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "OPC Chemical Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA7-OPC-ChemR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
      ],
    },
    {
      AreaName: "AO-8",
      AreaOwner: "Muhammad Shahbaz",
      Compliance : 47,SubAreas: [
        {
          SubAreaName: "ETP",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA8-ETP-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Boiler",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA8-Bioler-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Air Compressor",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA8-AirC-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Boiler Control Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA8-BiolerCR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "HFO Tank",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA8-HFO-T-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Water Filter Area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA8-WFA-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
      ],
    },
    {
      AreaName: "AO-9",
      AreaOwner: "Muhammad Wasi",
      Compliance : 23,SubAreas: [
        {
          SubAreaName: "Roof Tops (Canteen)",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-RT-C-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Roof Tops (Security)",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-RT-S-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Time Office",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-TimeO-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "ETMS",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-ETMS-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Medical OHC",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-M-OHC-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Security Office",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-SecO-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Parkings",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-Parking-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Cycle Stand",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-CycleS-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Smoking Area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-SmokA-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName:
            "Area between Multan road gate to inner factory entrance gate",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-MRG-IIFC-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Admin Building",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-AdminB-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Reception",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-Recep-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Canteen",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-Cantin-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Kitchen",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-Kitchn-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Galleries",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-G-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Washrooms",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-WR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Locker Area",
          CameraIP: "192.168.10.228 - 192.168.10.241",
          OutdoorIndoor: "N/A",
          NVR: "DHI-NVR4232",
          CameraID:"ICF-SA9-LockA-1-4232-228", 
          // [
          //   "ICF-SA9-LockA-1-4232-228",
          //   // "ICF-SA9-LockA-2-4232-229",
          //   // "ICF-SA9-LockA-3-4232-230",
          //   // "ICF-SA9-LockA-4-4232-231",
          //   // "ICF-SA9-LockA-5-4232-232",
          //   // "ICF-SA9-LockA-6-4232-233",
          //   // "ICF-SA9-LockA-7-4232-234",
          //   // "ICF-SA9-LockA-8-4232-235",
          //   // "ICF-SA9-LockA-9-4232-238",
          //   // "ICF-SA9-LockA-10-4232-239",
          //   // "ICF-SA9-LockA-11-4232-240",
          //   // "ICF-SA9-LockA-12-4232-241",
          // ],
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Masjid",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-mosq-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Changing Rooms",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-CR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Waiting Area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-WaitA-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Girls Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-GR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Exit Routes",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-exitR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Brains Lab",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-BrainL-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Recharge Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-RR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Humail's Office",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-HumailO-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Meeting Rooms",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-MR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "IT Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-ITR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Outside Taris",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA9-OST-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
      ],
    },
    {
      AreaName: "AO-10",
      AreaOwner: "Nazir Sb",
      Compliance : 14,SubAreas: [
        {
          SubAreaName: "Solar Area (Panels, Transformer rooms & entire area)",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA10-AO-PTEA-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Diesel Storage Area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA10-DAO-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Earth Pit Area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA10-EPA-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Electrical Power House",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA10-EPH-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "LT Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA10-LTR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "HT Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA10-HTR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Gen Set Area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA10-GAO-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Transformer Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA10-TransfR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Ammonia Soft Starter Room",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA10-ASSR-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
      ],
    },
    {
      AreaName: "AO-11",
      AreaOwner: "Sadia",
      Compliance : 13,SubAreas: [
        {
          SubAreaName: "R&D Innovation Centre (Complete)",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA11-RnD-IC-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
      ],
    },
    {
      AreaName: "AO-12",
      AreaOwner: "Shafiq",
      Compliance : 30,SubAreas: [
        {
          SubAreaName: "QA",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA12-QA-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Pathogen Lab",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA12-PLab-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "QA Storeroom",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA12-QAStore-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
      ],
    },
    {
      AreaName: "AO-13",
      AreaOwner: "Shahbaz",
      Compliance : 18,SubAreas: [
        {
          SubAreaName: "LPG Area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA13-LPGA-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Pump House",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA13-PumpH-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Water Treatment Plant & Roof",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA13-WTP-R-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Biomass Boiler (including fuel storage shed)",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA13-BioB-FSS-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
      ],
    },
    {
      AreaName: "AO-14",
      AreaOwner: "Sheraz",
      Compliance : 57,SubAreas: [
        {
          SubAreaName: "Roof Tops (Cold Stores)",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA14-RT-CS-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Wooden Pallets Area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA14-WPA-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "FG BOF",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA14-FG-BOF-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Cold Store 1&2",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA14-CS-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Cold Store Offices",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA14-CSO-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Dispatch Area",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA14-DA-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
      ],
    },
    {
      AreaName: "AO-15",
      AreaOwner: "Umair Pervaiz",
      Compliance : 68,SubAreas: [
        {
          SubAreaName: "UE Projects",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA15-UEP-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
        {
          SubAreaName: "Projects Store",
          CameraIP: "N/A",
          OutdoorIndoor: "N/A",
          NVR: "N/A",
          CameraID: "ICF-SA15-PStore-1-NVR-IP",
          Picture: "N/A",
          Helmet: "N/A",
          Vest: "N/A",
          EmergencyExit: "N/A",
          MachineGuard: "N/A",
          Forklift: "N/A",
        },
      ],
    },
  ];
  export const area1 = [
    {
      AreaName: "AO-1",
      AreaOwner: "Adil",
      SubAreas: [
        {
          SubAreaName: "Roof Tops (Palletizing)",
          Cameras: 1,
        },
        {
          SubAreaName: "Palletizing 1,2 & 3",
          Cmaeras: 1,
        },
        {
          SubAreaName: "Palletizing Office",
          Cameras: 1,
        },
        {
          SubAreaName: "Palletizing Corridor",
          Cameras: 1,
        },
        {
          SubAreaName: "Waste Window Area",
          Cameras: 1,
        },
      ],
    },
    {
      AreaName: "AO-2",
      AreaOwner: "Aftab",
      SubAreas: [
        {
          SubAreaName: "Roof Tops (Service Building)",
          Cameras: 8,
        },
        {
          SubAreaName: "Ammonia Compressor room",
          Cameras: 7,
        },
        {
          SubAreaName: "Catch pit area",
          Cameras: 3,
        },
        {
          SubAreaName: "Ref workshop",
          Cameras: 2,
        },
        {
          SubAreaName: "Ref Control Room",
          Cameras: 2,
        },
        {
          SubAreaName: "Ammonia CCR",
          Cameras: 2,
        },
      ],
    },
    {
      AreaName: "AO-3",
      AreaOwner: "Arslan",
      SubAreas: [
        {
          SubAreaName: "Void Area (Production, Mixing)",
          Cameras: 1,
        },
        {
          SubAreaName: "Admin Building Roof Top",
          Cameras: 1,
        },
        {
          SubAreaName: "AHU Room above Canteen",
          Cameras: 1,
        },
        {
          SubAreaName: "Main Asset scrap yard",
          Cameras: 1,
        },
        {
          SubAreaName: "Motor / Panel scrap yard",
          Cameras: 1,
        },
        {
          SubAreaName: "R&D front side scrap yard",
          Cameras: 1,
        },
        {
          SubAreaName: "Contractor Workshops",
          Cameras: 1,
        },
        {
          SubAreaName: "DP store area",
          Cameras: 1,
        },
        {
          SubAreaName: "Engineering Store",
          Cameras: 1,
        },
        {
          SubAreaName: "Safety Office",
          Cameras: 1,
        },
        {
          SubAreaName: "Safety Storage Area",
          Cameras: 1,
        },
        {
          SubAreaName: "Engineering Store Placement Yard",
          Cameras: 1,
        },
        {
          SubAreaName: "Fabrication Workshop & Surrounding Area",
          Cameras: 1,
        },
        {
          SubAreaName: "Lathe Machine Workshop",
          Cameras: 1,
        },
        {
          SubAreaName: "MAMz Workshop",
          Cameras: 1,
        },
      ],
    },
    {
      AreaName: "AO-4",
      AreaOwner: "Ayesha Khaliq",
      SubAreas: [
        {
          SubAreaName: "Roof Tops (Cone Baking)",
          Cameras: 1,
        },
        {
          SubAreaName: "Cone Baking",
          Cameras: 1,
        },
        {
          SubAreaName: "Mixing",
          Cameras: 1,
        },
        {
          SubAreaName: "LI Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Aging Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Chocolate Plant",
          Cameras: 1,
        },
        {
          SubAreaName: "Mixing Pits",
          Cameras: 1,
        },
        {
          SubAreaName: "Oil/Glucose Decanting Area",
          Cameras: 1,
        },
        {
          SubAreaName: "Sauce Plant",
          Cameras: 1,
        },
        {
          SubAreaName: "Chilled Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Day Store Area",
          Cameras: 1,
        },
        {
          SubAreaName: "Mixing Control Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Tank Form",
          Cameras: 1,
        },
      ],
    },
    {
      AreaName: "AO-5",
      AreaOwner: "Dr. Amjad",
      SubAreas: [
        {
          SubAreaName: "OHC",
          Cameras: 1,
        },
        {
          SubAreaName: "Medical Roof Top",
          Cameras: 1,
        },
      ],
    },
    {
      AreaName: "AO-6",
      AreaOwner: "Meraj",
      SubAreas: [
        {
          SubAreaName: "Roof Tops (Dry Store)",
          Cameras: 1,
        },
        {
          SubAreaName: "Roof Tops (Pulp Store)",
          Cameras: 1,
        },
        {
          SubAreaName: "Scrap Yard (Packmat area/drums)",
          Cameras: 1,
        },
        {
          SubAreaName: "Dry Store 1, 2",
          Cameras: 1,
        },
        {
          SubAreaName: "Chemical Store",
          Cameras: 1,
        },
        {
          SubAreaName: "Dry Store Driver Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Docking Stations",
          Cameras: 1,
        },
        {
          SubAreaName: "Washrooms",
          Cameras: 1,
        },
        {
          SubAreaName: "Pulp Store",
          Cameras: 1,
        },
        {
          SubAreaName: "Hot Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Flavour Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Pallet Washing Room",
          Camerasa: 1,
        },
      ],
    },
    {
      AreaName: "AO-7",
      AreaOwner: "Moazzam Ali",
      SubAreas: [
        {
          SubAreaName: "Machine Parts Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Ultra Clean",
          Cameras: 1,
        },
        {
          SubAreaName: "Production Floor",
          Cameras: 1,
        },
        {
          SubAreaName: "Production Offices",
          Cameras: 1,
        },
        {
          SubAreaName: "TPM Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Day Store",
          Cameras: 1,
        },
        {
          SubAreaName: "Parts Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Room 10",
          Cameras: 1,
        },
        {
          SubAreaName: "OPC Chemical Room",
          Cameras: 1,
        },
      ],
    },
    {
      AreaName: "AO-8",
      AreaOwner: "Muhammad Shahbaz",
      SubAreas: [
        {
          SubAreaName: "ETP",
          Cameras: 1,
        },
        {
          SubAreaName: "Boiler",
          Cameras: 1,
        },
        {
          SubAreaName: "Air Compressor",
          Cameras: 1,
        },
        {
          SubAreaName: "Boiler Control Room",
          Cameras: 1,
        },
        {
          SubAreaName: "HFO Tank",
          Cameras: 1,
        },
        {
          SubAreaName: "Water Filter Area",
          Cameras: 1,
        },
      ],
    },
    {
      AreaName: "AO-9",
      AreaOwner: "Muhammad Wasi",
      SubAreas: [
        {
          SubAreaName: "Roof Tops (Canteen)",
          Cameras: 1,
        },
        {
          SubAreaName: "Roof Tops (Security)",
          Cameras: 1,
        },
        {
          SubAreaName: "Time Office",
          Cameras: 1,
        },
        {
          SubAreaName: "ETMS",
          Cameras: 1,
        },
        {
          SubAreaName: "Medical OHC",
          Cameras: 1,
        },
        {
          SubAreaName: "Security Office",
          Cameras: 1,
        },
        {
          SubAreaName: "Parkings",
          Cameras: 1,
        },
        {
          SubAreaName: "Cycle Stand",
          Cameras: 1,
        },
        {
          SubAreaName: "Smoking Area",
          Cameras: 1,
        },
        {
          SubAreaName: "Area between Multan road gate to inner factory entrance gate",
          Cameras: 1,
        },
        {
          SubAreaName: "Admin Building",
          Cameras: 1,
        },
        {
          SubAreaName: "Reception",
          Cameras: 1,
        },
        {
          SubAreaName: "Canteen",
          Cameras: 1,
        },
        {
          SubAreaName: "Kitchen",
          Cameras: 1,
        },
        {
          SubAreaName: "Galleries",
          Cameras: 1,
        },
        {
          SubAreaName: "Washrooms",
          Cameras: 1,
        },
        {
          SubAreaName: "Locker Area",
          Cameras: 12,
        },
        {
          SubAreaName: "Masjid",
          Cameras: 1,
        },
        {
          SubAreaName: "Changing Rooms",
          Cameras: 1,
        },
        {
          SubAreaName: "Waiting Area",
          Cameras: 1,
        },
        {
          SubAreaName: "Girls Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Exit Routes",
          Cameras: 1,
        },
        {
          SubAreaName: "Brains Lab",
          Cameras: 1,
        },
        {
          SubAreaName: "Recharge Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Humail's Office",
          Cameras: 1,
        },
        {
          SubAreaName: "Meeting Rooms",
          Cameras: 1,
        },
        {
          SubAreaName: "IT Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Outside Taris",
          Cameras: 1,
        },
      ],
    },
    {
      AreaName: "AO-10",
      AreaOwner: "Nazir Sb",
      SubAreas: [
        {
          SubAreaName: "Solar Area (Panels, Transformer rooms & entire area)",
          Cameras: 1,
        },
        {
          SubAreaName: "Diesel Storage Area",
          Cameras: 1,
        },
        {
          SubAreaName: "Earth Pit Area",
          Cameras: 1,
        },
        {
          SubAreaName: "Electrical Power House",
          Cameras: 1,
        },
        {
          SubAreaName: "LT Room",
          Cameras: 1,
        },
        {
          SubAreaName: "HT Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Gen Set Area",
          Cameras: 1,
        },
        {
          SubAreaName: "Transformer Room",
          Cameras: 1,
        },
        {
          SubAreaName: "Ammonia Soft Starter Room",
          Cameras: 1,
        },
      ],
    },
    {
      AreaName: "AO-11",
      AreaOwner: "Sadia",
      SubAreas: [
        {
          SubAreaName: "R&D Innovation Centre (Complete)",
          Cameras: 1,
        },
      ],
    },
    {
      AreaName: "AO-12",
      AreaOwner: "Shafiq",
      SubAreas: [
        {
          SubAreaName: "QA",
          Cameras: 1,
        },
        {
          SubAreaName: "Pathogen Lab",
          Cameras: 1,
        },
        {
          SubAreaName: "QA Storeroom",
          Cameras: 1,
        },
      ],
    },
    {
      AreaName: "AO-13",
      AreaOwner: "Shahbaz",
      SubAreas: [
        {
          SubAreaName: "LPG Area",
          Cameras: 1,
        },
        {
          SubAreaName: "Pump House",
          Cameras: 1,
        },
        {
          SubAreaName: "Water Treatment Plant & Roof",
          Cameras: 1,
        },
        {
          SubAreaName: "Biomass Boiler (including fuel storage shed)",
          Cameras: 1,
        },
      ],
    },
    {
      AreaName: "AO-14",
      AreaOwner: "Sheraz",
      SubAreas: [
        {
          SubAreaName: "Roof Tops (Cold Stores)",
          Cameras: 1,
        },
        {
          SubAreaName: "Wooden Pallets Area",
          Cameras: 1,
        },
        {
          SubAreaName: "FG BOF",
          Cameras: 1,
        },
        {
          SubAreaName: "Cold Store 1&2",
          Cameras: 1,
        },
        {
          SubAreaName: "Cold Store Offices",
          Cameras: 1,
        },
        {
          SubAreaName: "Dispatch Area",
          Cameras: 1,
        },
      ],
    },
    {
      AreaName: "AO-15",
      AreaOwner: "Umair Pervaiz",
      SubAreas: [
        {
          SubAreaName: "UE Projects",
          Cameras: 1,
        },
        {
          SubAreaName: "Projects Store",
          Cameras: 1,
        },
      ],
    },
  ];
  
  
  
  
  
  
  
  
 
 
  // export const StaticDataForAO6 = [
  //   {
  //     cameraName: 'Dry store 2 Camera 1',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D2C1-1-2-10-53',
  //         CameraIP: 'idd'
          
  //       }, 
  //     ],
  //   },
  //   {
  //     cameraName: 'Dry store 2 Camera 2',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D2C2-2-2-10-58',
  //       },
  //     ],
  //   },
  //   {
  //     cameraName: 'Dry store 2 Camera 3',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D2C3-3-2-10-60',
  //       },
      
  //     ],
  //   },
  //   {
  //     cameraName: 'Dry store 2 Camera 4',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D2C4-4-2-10-62',
  //       },
  //     ],
  //   },
  //   {
  //     cameraName: 'Dry store 1 Camera 1',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D2C4-4-2-10-62',
  //       },
  //     ],
  //   },
  //   {
  //     cameraName: 'Dry store 1 Camera 2',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D1C1-5-2-10-63',
  //       },
  //     ],
  //   },
  //   {
  //     cameraName: 'Dry store 1 Camera 3',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D1C2-6-2-10-64',
  //       },
  //     ],
  //   },
  //   {
  //     cameraName: 'Dry store 1 Camera 4',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D1C3-7-2-10-65',
  //       },
  //     ],
  //   },
  //   {
  //     cameraName: 'Dry store 1 Camera 5',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D1C4-8-2-10-66',
  //       },
  //     ],
  //   },
  //   {
  //     cameraName: 'Dry store 1 Camera 6',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D1C5-9-2-10-67',
  //       },
    
  //     ],
  //   },
  //   {
  //     cameraName: 'Dry store 1 Camera 7',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D1C6-10-2-10-71',
  //       },
  //     ],
  //   },
  //   {
  //     cameraName: 'Dry store 2 Camera 6',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D1C7-11-2-10-72',
  //       },
       
  //     ],
  //   },
  //   {
  //     cameraName: 'Dry store 2 Camera 5',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D2C6-12-2-10-54',
  //       },
       
  //     ],
  //   },
  //   {
  //     cameraName: 'Dry store 2 Camera 7',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D2C5-13-2-10-55',
  //       },
       
  //     ],
  //   },
  //   {
  //     cameraName: 'Dry store 2 Camera 8',
  //     SubAreas: [
  //       {
  //         SubAreaName: "DRY Store 1, 2",
  //         CameraID: 'ICF-AO6-D2C7-14-2-10-59',
  //       },
  //     ],
  //   },
  // ];
  
  export const StaticDataForAO6 = [
    {
      cameraName: 'Dry store 2 Camera 1',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D2C1-1-2-10-53',
          CameraIP: '192.168.10.53',
          complient: 78
        },
      ],
    },
    {
      cameraName: 'Dry store 2 Camera 2',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D2C2-2-2-10-58',
          CameraIP: '192.168.10.58',
          complient: 80
        },
      ],
    },
    {
      cameraName: 'Dry store 2 Camera 3',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D2C3-3-2-10-60',
          CameraIP: '192.168.10.60',
          complient: 80
        },
      ],
    },
    {
      cameraName: 'Dry store 2 Camera 4',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D2C4-4-2-10-62',
          CameraIP: '192.168.10.62',
          complient: 99
        },
      ],
    },
    {
      cameraName: 'Dry store 1 Camera 1',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D2C4-4-2-10-62',
          CameraIP: '192.168.10.63',
          complient: 85
        },
      ],
    },
    {
      cameraName: 'Dry store 1 Camera 2',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D1C1-5-2-10-63',
          CameraIP: '192.168.10.64',
          complient: 66
        },
      ],
    },
    {
      cameraName: 'Dry store 1 Camera 3',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D1C2-6-2-10-64',
          CameraIP: '192.168.10.65',
          complient: 80
        },
      ],
    },
    {
      cameraName: 'Dry store 1 Camera 4',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D1C3-7-2-10-65',
          CameraIP: '192.168.10.66',
          complient: 80
        },
      ],
    },
    {
      cameraName: 'Dry store 1 Camera 5',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D1C4-8-2-10-66',
          CameraIP: '192.168.10.67',
          complient: 80
        },
      ],
    },
    {
      cameraName: 'Dry store 1 Camera 6',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D1C5-9-2-10-67',
          CameraIP: '192.168.10.71',
          complient: 100
        },
      ],
    },
    {
      cameraName: 'Dry store 1 Camera 7',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D1C6-10-2-10-71',
          CameraIP: '192.168.10.72',
          complient: 66
        },
      ],
    },
    {
      cameraName: 'Dry store 2 Camera 6',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D1C7-11-2-10-72',
          CameraIP: '192.168.10.54',
          complient: 80
        },
      ],
    },
    {
      cameraName: 'Dry store 2 Camera 5',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D2C6-12-2-10-54',
          CameraIP: '192.168.10.55',
          complient: 80
        },
      ],
    },
    {
      cameraName: 'Dry store 2 Camera 7',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D2C5-13-2-10-55',
          CameraIP: '192.168.10.59',
          complient: 80
        },
      ],
    },
    {
      cameraName: 'Dry store 2 Camera 8',
      SubAreas: [
        {
          SubAreaName: "DRY Store 1, 2",
          CameraID: 'ICF-AO6-D2C7-14-2-10-59',
          CameraIP: '192.168.10.52',
          complient: 80
        },
      ],
    },
  ];

  
  
  export const area2 = [
    {
      AreaName: "AO-1",
      AreaOwner: "Adil",
      SubAreas: [ 
        {
          SubAreaName: "Roof Tops (Palletizing)",
        Cameras:1,
        Compliance : 84,
        },
        {
          SubAreaName: "Palletizing 1,2 & 3",
      Cmaeras:1,
      Compliance : 84,
        },
        {
          SubAreaName: "Palletizing Office",
        Cameras:1,
        Compliance : 84,
        },
        {
          SubAreaName: "Palletizing Corridor",
       Cameras:1,
       Compliance : 84,
        },
        {
          SubAreaName: "Waste Window Area",
          Cameras:1,
          Compliance : 84,

        },
      ],
    },
    {
      AreaName: "AO-2",
      AreaOwner: "Aftab",
     
      SubAreas: [
        {
          SubAreaName: "Roof Tops (Service Building)",
          Cameras:8,
          Compliance : 95,

        },
       
        {
          SubAreaName: "Ammonia Compressor room",
          Cameras:7,
          Compliance : 95,

        },
     
  
        {
          SubAreaName: "Catch pit area",
          Cameras:3,
          Compliance : 95,

        },
      
  
        {
          SubAreaName: "Ref workshop",
          Cameras:2,
          Compliance : 95,

        },
      
        {
          SubAreaName: "Ref Control Room",
          Cameras:2,
          Compliance : 95,

        },
      
        {
          SubAreaName: "Ammonia CCR",
          Cameras:2,
          Compliance : 95,

        },
    
      ],
    },
    {
      AreaName: "AO-3",
      AreaOwner: "Arslan",
      SubAreas: [
        {
          SubAreaName: "Void Area (Production, Mixing)",
          Cameras:1,
          Compliance : 40
     
        },
        {
          SubAreaName: "Admin Building Roof Top",
          Cameras:1,
          Compliance : 40

        },
        {
          SubAreaName: "AHU Room above Canteen",
          Cameras:1,
          Compliance : 40

        },
        {
          SubAreaName: "Main Asset scrap yard",
          Cameras:1,
          Compliance : 40

        },
        {
          SubAreaName: "Motor / Panel scrap yard",
          Cameras:1,
          Compliance : 40

        },
        {
          SubAreaName: "R&D front side scrap yard",
          Cameras:1,
          Compliance : 40

        },
        {
          SubAreaName: "Contractor Workshops",
          Cameras:1,
          Compliance : 40

        },
        {
          SubAreaName: "DP store area",
          Cameras:1,
          Compliance : 40

        },
        {
          SubAreaName: "Engineering Store",
          Cameras:1,
          Compliance : 40

        },
        {
          SubAreaName: "Safety Office",
          Cameras:1,
          Compliance : 40

        },
        {
          SubAreaName: "Safety Storage Area",
          Cameras:1,
          Compliance : 40

        },
        {
          SubAreaName: "Engineering Store Placement Yard",
          Cameras:1,
          Compliance : 40

        },
        {
          SubAreaName: "Fabrication Workshop & Surrounding Area",
          Cameras:1,
          Compliance : 40

        },
        {
          SubAreaName: "Lathe Machine Workshop",
          Cameras:1,
          Compliance : 40

        },
        {
          SubAreaName: "MAMz Workshop",
          Cameras:1,
          Compliance : 40

        },
      ],
    },
    {
      AreaName: "AO-4",
      AreaOwner: "Ayesha Khaliq",
      SubAreas: [
        {
          SubAreaName: "Roof Tops (Cone Baking)",
          Cameras:1,
          Compliance : 90,

        },
        {
          SubAreaName: "Cone Baking",
          Cameras:1,
          Compliance : 90,

        },
        {
          SubAreaName: "Mixing",
          Cameras:1,
          Compliance : 90,

        },
        {
          SubAreaName: "LI Room",
          Cameras:1,
          Compliance : 90,

        },
        {
          SubAreaName: "Aging Room",
          Cameras:1,
          Compliance : 90,

        },
        {
          SubAreaName: "Chocolate Plant",
          Cameras:1,
          Compliance : 90,

        },
        {
          SubAreaName: "Mixing Pits",
          Cameras:1,
          Compliance : 90,

        },
        {
          SubAreaName: "Oil/Glucose Decanting Area",
          Cameras:1,
          Compliance : 90,

        },
        {
          SubAreaName: "Sauce Plant",
          Cameras:1,
          Compliance : 90,

        },
        {
          SubAreaName: "Chilled Room",
          Cameras:1,
          Compliance : 90,

        },
        {
          SubAreaName: "Day Store Area",
          Cameras:1,
          Compliance : 90,

        },
        {
          SubAreaName: "Mixing Control Room",
          Cameras:1,
          Compliance : 90,

        },
        {
          SubAreaName: "Tank Form",
          Cameras:1,
          Compliance : 90,

        },
      ],
    },
    {
      AreaName: "AO-5",
      AreaOwner: "Dr. Amjad",
      Compliance : 78,SubAreas: [
        {
          SubAreaName: "OHC",
          Cameras:1

        },
        {
          SubAreaName: "Medical Roof Top",
          Cameras:1

        },
      ],
    },
    {
      AreaName: "AO-6",
      AreaOwner: "Meraj",
      Compliance : 85,SubAreas: [
        {
          SubAreaName: "Roof Tops (Dry Store)",
          Cameras:1

        },
        {
          SubAreaName: "Roof Tops (Pulp Store)",
          Cameras:1

        },
        {
          SubAreaName: "Scrap Yard (Packmat area/drums)",
          Cameras:1

        },
        {
          SubAreaName: "Dry Store 1, 2",
          Cameras:1

        },
        {
          SubAreaName: "Chemical Store",
          Cameras:1

        },
        {
          SubAreaName: "Dry Store Driver Room",
          Cameras:1

        },
        {
          SubAreaName: "Docking Stations",
          Cameras:1

        },
        {
          SubAreaName: "Washrooms",
          Cameras:1

        },
        {
          SubAreaName: "Pulp Store",
          Cameras:1

        },
        {
          SubAreaName: "Hot Room",
        Cameras:1
        },
        {
          SubAreaName: "Flavour Room",
     Cameras:1
        },
        {
          SubAreaName: "Pallet Washing Room",
          Camerasa:1
        },
      ],
    },
    {
      AreaName: "AO-7",
      AreaOwner: "Moazzam Ali",
      Compliance : 87,SubAreas: [
        {
          SubAreaName: "Machine Parts Room",
          Cameras:1
        },
        {
          SubAreaName: "Ultra Clean",
          Cameras:1
        },
        {
          SubAreaName: "Production Floor",
          Cameras:1
        },
        {
          SubAreaName: "Production Offices",
          Cameras:1

        },
        {
          SubAreaName: "TPM Room",
          Cameras:1

        },
        {
          SubAreaName: "Day Store",
          Cameras:1

        },
        {
          SubAreaName: "Parts Room",
          Cameras:1

        },
        {
          SubAreaName: "Room 10",
          Cameras:1

        },
        {
          SubAreaName: "OPC Chemical Room",
          Cameras:1

        },
      ],
    },
    {
      AreaName: "AO-8",
      AreaOwner: "Muhammad Shahbaz",
      Compliance : 47,SubAreas: [
        {
          SubAreaName: "ETP",
          Cameras:1

        },
        {
          SubAreaName: "Boiler",
          Cameras:1

        },
        {
          SubAreaName: "Air Compressor",
          Cameras:1

        },
        {
          SubAreaName: "Boiler Control Room",
          Cameras:1

        },
        {
          SubAreaName: "HFO Tank",
          Cameras:1

        },
        {
          SubAreaName: "Water Filter Area",
          Cameras:1

        },
      ],
    },
    {
      AreaName: "AO-9",
      AreaOwner: "Muhammad Wasi",
      Compliance : 23,SubAreas: [
        {
          SubAreaName: "Roof Tops (Canteen)",
          Cameras:1

        },
        {
          SubAreaName: "Roof Tops (Security)",
          Cameras:1

        },
        {
          SubAreaName: "Time Office",
          Cameras:1

        },
        {
          SubAreaName: "ETMS",
          Cameras:1

        },
        {
          SubAreaName: "Medical OHC",
          Cameras:1

        },
        {
          SubAreaName: "Security Office",
          Cameras:1

        },
        {
          SubAreaName: "Parkings",
          Cameras:1

        },
        {
          SubAreaName: "Cycle Stand",
          Cameras:1

        },
        {
          SubAreaName: "Smoking Area",
          Cameras:1

        },
        {
          SubAreaName:
            "Area between Multan road gate to inner factory entrance gate",
            Cameras:1

        },
        {
          SubAreaName: "Admin Building",
          Cameras:1

        },
        {
          SubAreaName: "Reception",
          Cameras:1

        },
        {
          SubAreaName: "Canteen",
          Cameras:1

        },
        {
          SubAreaName: "Kitchen",
          Cameras:1

        },
        {
          SubAreaName: "Galleries",
          Cameras:1

        },
        {
          SubAreaName: "Washrooms",
          Cameras:1

        },
        {
          SubAreaName: "Locker Area",
          Cameras:12

        },
        {
          SubAreaName: "Masjid",
          Cameras:1

        },
        {
          SubAreaName: "Changing Rooms",
          Cameras:1

        },
        {
          SubAreaName: "Waiting Area",
          Cameras:1

        },
        {
          SubAreaName: "Girls Room",
          Cameras:1

        },
        {
          SubAreaName: "Exit Routes",
          Cameras:1

        },
        {
          SubAreaName: "Brains Lab",
          Cameras:1

        },
        {
          SubAreaName: "Recharge Room",
          Cameras:1

        },
        {
          SubAreaName: "Humail's Office",
          Cameras:1

        },
        {
          SubAreaName: "Meeting Rooms",
          Cameras:1

        },
        {
          SubAreaName: "IT Room",
          Cameras:1

        },
        {
          SubAreaName: "Outside Taris",
          Cameras:1

        },
      ],
    },
    {
      AreaName: "AO-10",
      AreaOwner: "Nazir Sb",
      Compliance : 14,SubAreas: [
        {
          SubAreaName: "Solar Area (Panels, Transformer rooms & entire area)",
          Cameras:1

        },
        {
          SubAreaName: "Diesel Storage Area",
          Cameras:1

        },
        {
          SubAreaName: "Earth Pit Area",
          Cameras:1

        },
        {
          SubAreaName: "Electrical Power House",
          Cameras:1

        },
        {
          SubAreaName: "LT Room",
          Cameras:1

        },
        {
          SubAreaName: "HT Room",
          Cameras:1

        },
        {
          SubAreaName: "Gen Set Area",
          Cameras:1

        },
        {
          SubAreaName: "Transformer Room",
          Cameras:1

        },
        {
          SubAreaName: "Ammonia Soft Starter Room",
          Cameras:1

        },
      ],
    },
    {
      AreaName: "AO-11",
      AreaOwner: "Sadia",
      Compliance : 13,SubAreas: [
        {
          SubAreaName: "R&D Innovation Centre (Complete)",
          Cameras:1

        },
      ],
    },
    {
      AreaName: "AO-12",
      AreaOwner: "Shafiq",
      Compliance : 30,SubAreas: [
        {
          SubAreaName: "QA",
          Cameras:1

        },
        {
          SubAreaName: "Pathogen Lab",
          Cameras:1

        },
        {
          SubAreaName: "QA Storeroom",
          Cameras:1

        },
      ],
    },
    {
      AreaName: "AO-13",
      AreaOwner: "Shahbaz",
      Compliance : 18,SubAreas: [
        {
          SubAreaName: "LPG Area",
          Cameras:1

        },
        {
          SubAreaName: "Pump House",
          Cameras:1

        },
        {
          SubAreaName: "Water Treatment Plant & Roof",
          Cameras:1

        },
        {
          SubAreaName: "Biomass Boiler (including fuel storage shed)",
          Cameras:1

        },
      ],
    },
    {
      AreaName: "AO-14",
      AreaOwner: "Sheraz",
      Compliance : 57,SubAreas: [
        {
          SubAreaName: "Roof Tops (Cold Stores)",
          Cameras:1

        },
        {
          SubAreaName: "Wooden Pallets Area",
          Cameras:1

        },
        {
          SubAreaName: "FG BOF",
          Cameras:1

        },
        {
          SubAreaName: "Cold Store 1&2",
          Cameras:1

        },
        {
          SubAreaName: "Cold Store Offices",
          Cameras:1

        },
        {
          SubAreaName: "Dispatch Area",
          Cameras:1

        },
      ],
    },
    {
      AreaName: "AO-15",
      AreaOwner: "Umair Pervaiz",
      Compliance : 68,SubAreas: [
        {
          SubAreaName: "UE Projects",
          Cameras:1

        },
        {
          SubAreaName: "Projects Store",
          Cameras:1

        },
      ],
    },
  ];
export const SA = ['Select Area','AO-1','AO-2','AO-3','AO-4','AO-5','AO-6','AO-7','AO-8','AO-9','AO-10','AO-11','AO-12','AO-13','AO-14','AO-15']

export const dateChoose = ['Month', 'Daily','Week','Custom']