import React from 'react'
import GlobalTargetAndControls from '../../../Screens/GlobalUser/TargetsAndControls/targets_and_controls';

const TargetsAndControls = () => {
  return (
    <>
      <GlobalTargetAndControls area={true} />
    </>
  )
}

export default TargetsAndControls
