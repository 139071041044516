import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { H5, H6 } from '../../../../../../AbstractElements'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom';


function NewCards({data}) {
    const navigate = useNavigate();
  return (
    <>
    <Card>
        <CardBody>
    <Row>
    {
        data.map((mod)=>{
            return(
                <>
                
                    <Col xxl={6} xl={12} lg={12}>
                    <Card style={{backgroundColor:`${mod.background}`, borderColor:mod.border, borderRadius:'10px', border:`1px solid ${mod.border}`}}>
                        <CardBody>
                        <div className='d-flex justify-content-between align-items-center mt-1'>
                        <p className='m-0 p-0' style={{fontSize:'24px', fontWeight:'500'}}>{mod.module}</p>
                   <div
  style={{
    position: "relative",
    width: "60px", // Slightly larger size to match the visual
    height: "60px",
    borderRadius: "50%",
    background: "conic-gradient(#54BA4A 0% 80%, #d3d3d3 80% 100%)", // Match the green and gray
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
>
  <div
    style={{
      position: "absolute",
      width: "50px", // Inner circle to create the donut effect
      height: "50px",
      borderRadius: "50%",
      backgroundColor: `${mod.background}`, // Matches the inner background
    }}
  ></div>
  <span
    style={{
      position: "absolute",
      fontSize: "13px", // Larger text to match
      fontWeight: "bold",
      color: "#333", // Darker text color
    }}
  >
    {mod.compliancePercentage}%
  </span>
</div>

                        </div>
                        <hr />
                        <div className='d-flex flex-wrap justify-content-between'>
                            <div>
                                <p className='p-0 m-0' style={{color:'#696969'}}>Total Cameras</p>
                                <p className='p-0 m-0' >{mod.totalCameras}</p>
                            </div>
                            <div className='mx-2 mt-2' style={{width:'1px', height:'35px', backgroundColor:`${mod.border}`,color:`${mod.border}`}}></div>
                            <div>
                                <p className='p-0 m-0' style={{color:'#696969'}}>Frame</p>
                                <p className='p-0 m-0' >{mod.frames}</p>
                            </div>
                            <div className='mx-2 mt-2' style={{width:'1px', height:'35px', backgroundColor:`${mod.border}`,color:`${mod.border}`}}></div>
                            <div>
                                <p className='p-0 m-0' style={{color:'#696969'}}>Event</p>
                                <p className='p-0 m-0' >{mod.events}</p>
                            </div>
                            <div className='mx-2 mt-2' style={{width:'1px', height:'35px', backgroundColor:`${mod.border}`,color:`${mod.border}`}}></div>
                            <div>
                                <p className='p-0 m-0' style={{color:'#696969'}}>Compliance</p>
                                <p className='p-0 m-0' >{mod.compliance}</p>
                            </div>
                            <div className='mx-2 mt-2' style={{width:'1px', height:'35px', backgroundColor:`${mod.border}`,color:`${mod.border}`}}></div>
                            <div>
                                <p className='p-0 m-0' style={{color:'#696969'}}>Non-Compliance</p>
                                <p className='p-0 m-0' >{mod.nonCompliance}</p>
                            </div>
                            <div className='d-flex align-items-end'>
                                <Link state={{ cameras: mod.cameras, module: mod.module }} to={`${process.env.PUBLIC_URL}/moduleInfo/${JSON.parse(localStorage.getItem('role'))}`} style={{color:'#175FA4',  textDecoration: "underline", cursor:'pointer'}} className='p-0 m-0' >See More</Link>
                            </div>
                            
                        </div>
                        </CardBody>
                    </Card>
                    </Col>
                
                </>
            )
        })
    }
    </Row>
    </CardBody>
    </Card>
    </>
  )
}

export default NewCards