import React, { Fragment, useEffect, useState } from 'react';
import { Button, Container, FormGroup, Input } from 'reactstrap';
import { Row, Col, Card } from "react-bootstrap";
import TopLeaderCard from './Component/Top_Leader_Card';
import Table from './Component/Table/DataTable'
import Loader1 from "../../../../CommonElements/Spinner/loader";
import DatePicker from "react-datepicker";
import './style/style.css';
import YearPicker from './Component/YearComponent/Select_Year';
import AreaService from '../../../../api/areaService';
import { errorToast } from '../../../../_helper/helper';

const LeaderBoard = () => {
  const [Leaders, setLeaders] = useState([]);
  const [TableData, setTableData] = useState([]);
  const [FileteredLeaders, setFileteredLeaders] = useState([])
  const [FileteredTable, setFileteredTable] = useState([])
  const [loader, setLoader] = useState(true);
  const [filterType, setFilterType] = useState('today');
  const [currentweek, setcurrentweek] = useState('');
  const [filterTable, setfilterTable] = useState([])
  const [filters, setFilters] = useState({
    week: '',
    month: '',
    year: ''
  })

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = (today.getMonth() + 1).toString().padStart(2, '0');

  const employeeData = [
    {
      Name: "Adil",
      Phone: "+92 300 123 4567",
      Score: 101,
      Status: "Active",
      totalalert: '101',
      areaid: 'AO-1',
      points: 30,
      compliance: 40,
      time: '7 mins'
    },
    {
      Name: "Aftab",
      Phone: "+92 301 234 5678",
      Score: 99,
      Status: "Active",
      totalalert: '114',
      areaid: 'AO-2',
      points: 30,
      compliance: 40,
      time: '7 mins'
    },
    {
      Name: "Arsalan",
      Phone: "+92 302 345 6789",
      Score: 98,
      Status: "Active",
      totalalert: '127',
      areaid: 'AO-3',
      points: 30,
      compliance: 40,
      time: '7 mins'
    },
    {
      Name: "Ayesha",
      Phone: "+48 608 608 608",
      Score: 92,
      Status: "Active",
      totalalert: '92',
      areaid: 'AO-4',
      points: 30,
      compliance: 40,
      time: '7 mins'
    },
    {
      Name: "Dr. Amjad",
      Phone: "+48 612 432 612",
      Score: 89,
      Status: "Active",
      totalalert: '89',
      areaid: 'AO-5',
      points: 30,
      compliance: 40,
      time: '7 mins'
    },
    {
      Name: "Meraj",
      Phone: "+48 508 508 508",
      Score: 75,
      Status: "Inactive",
      totalalert: '75',
      areaid: 'AO-6',
      points: 30,
      compliance: 40,
      time: '7 mins'
    },
    {
      Name: "Moazzam Ali",
      Phone: "+42 678 000 111",
      Score: 56,
      Status: "Active",
      totalalert: '56',
      areaid: 'AO-7',
      points: 30,
      compliance: 40,
      time: '7 mins'
    },
    {
      Name: "Muhammad Shehbaz",
      Phone: "+92 304 123 4567",
      Score: 72,
      Status: "Active",
      totalalert: '72',
      areaid: 'AO-8',
      points: 30,
      compliance: 40,
      time: '7 mins'
    },
    {
      Name: "Muhammad Wasi",
      Phone: "+92 305 234 5678",
      Score: 85,
      Status: "Active",
      totalalert: '85',
      areaid: 'AO-9',
      points: 30,
      compliance: 40,
      time: '7 mins'
    },
    {
      Name: "Nazir Sb",
      Phone: "+92 306 345 6789",
      Score: 80,
      Status: "Active",
      totalalert: '80',
      areaid: 'AO-10',
      points: 30,
      compliance: 40,
      time: '7 mins'
    },
    {
      Name: "Sadia",
      Phone: "+92 307 456 7890",
      Score: 67,
      Status: "Inactive",
      totalalert: '67',
      areaid: 'AO-11',
      points: 30,
      compliance: 40,
      time: '7 mins'
    },
    {
      Name: "Shafiq",
      Phone: "+92 308 567 8901",
      Score: 74,
      Status: "Active",
      totalalert: '74',
      areaid: 'AO-12',
      points: 30,
      compliance: 40,
      time: '7 mins'
    },
    {
      Name: "Shehbaz",
      Phone: "+92 309 678 9012",
      Score: 65,
      Status: "Inactive",
      totalalert: '64',
      areaid: 'AO-13',
      points: 30,
      compliance: 40,
      time: '7 mins'
    },
    {
      Name: "Sheraz",
      Phone: "+92 309 678 9012",
      Score: 65,
      Status: "Inactive",
      totalalert: '66',
      areaid: 'AO-14',
      points: 30,
      compliance: 40,
      time: '7 mins'
    },
    {
      Name: "Umair Pervaiz",
      Phone: "+92 309 678 9012",
      Score: 65,
      Status: "Inactive",
      totalalert: '69',
      areaid: 'AO-15',
      points: 30,
      compliance: 40,
      time: '7 mins'
    }
  ];

  const handleFilterChange = (e) => {
    setFilterType(e.target.value);

    if (e.target.value == 'monthly') {
      setFilters({
        ...filters,
        week: ''
      })
    }
    else if (e.target.value == 'weekly') {
      setFilters({
        ...filters,
        month: ''
      })
    }
    else {
      console.log('hello broo')
      setFileteredLeaders([]);
      setFileteredTable([]);
      setfilterTable([])
      setFilters({
        month: '',
        week: ''
      })
    }
  };
  const handleYearChange = date => {
    setFilters({ ...filters, year: date });
  };

  const getCurrentWeek = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const firstDayOfYear = new Date(currentYear, 0, 1); // January 1st
    const daysPassed = (today - firstDayOfYear) / 86400000; // Convert milliseconds to days
    let currentWeekNumber = Math.ceil((daysPassed + firstDayOfYear.getDay() + 1) / 7); // Week calculation
  
    // Previous week number
    let previousWeekNumber = currentWeekNumber - 1;
  
    if (previousWeekNumber < 1) {
      // If it is the first week and we subtract one, calculate the last week of the previous year
      const lastDayOfLastYear = new Date(currentYear - 1, 11, 31); // December 31st of the last year
      const firstDayOfLastYear = new Date(currentYear - 1, 0, 1); // January 1st of the last year
      const daysInLastYear = (lastDayOfLastYear - firstDayOfLastYear) / 86400000;
      const lastYearWeekNumber = Math.ceil((daysInLastYear + firstDayOfLastYear.getDay() + 1) / 7);
      previousWeekNumber = lastYearWeekNumber;
    }
  
    return previousWeekNumber;
  };
  
  const handleInputChange = (e, field) => {
    setFilters({
      ...filters,
      [field]: e.target.value,
    });
    console.log("all filters", filters);
  };

  useEffect(() => {
    const weekNumber = getCurrentWeek();
    const maxWeek = `${currentYear}-W${String(weekNumber).padStart(2, "0")}`;
    console.log(maxWeek)
    setcurrentweek(maxWeek);
  }, [])
  const updateCompliance = (data) => {
    return data.map(item => {
      if (item.totalalert === 0) { // Ensure no division by zero
        const compliancePercentage = 100
        return {
          ...item,
          compliance: compliancePercentage,
          highseverityalerts: '9%',
          points: compliancePercentage >= 80 ? 100 : 75,
          time: '10'
        };
      }else if(item.totalalert > 0) {
        const compliancePercentage = (item.compliance / (item.totalalert + item.compliance)) * 100;
        return {
          ...item,
          compliance: compliancePercentage.toFixed(0),
          highseverityalerts: '9%',
          points: compliancePercentage >= 80 ? 100 : 75,
          time: '10'
        };
      }
      return item;
    });
  };
  const sortData = (data) => {
    return data.sort((a, b) => {
      // Check if the points are equal
      if (a.points === b.points) {
        return a.totalalert - b.totalalert; // Sorting by totalalert if points are equal, lower first
      }
      // Otherwise, sort by points, higher first
      return b.points - a.points;
    });
  };
  function fWeekForAPI() {
    const currentDate = new Date(); // Get the current date
    const startDate = new Date(currentDate.getFullYear(), 0, 1); // First day of the current year
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000)); // Difference in days

    let weekNumber = Math.ceil((days + startDate.getDay() + 1) / 7); // Calculate the week number
    if (startDate.getDay() > 4) {
        weekNumber -= 1; // Adjust if year started on a weekend
    }

    // Format the week number to match "YYYY-WW" format
    return `${currentDate.getFullYear()}-W${weekNumber.toString().padStart(2, '0')}`;
}

  const fetchleaderboard = async () => {
    const weeknumber= fWeekForAPI()
    try {
      const payload = {
        flag: true,
        week:weeknumber
      };
      const res = await AreaService.fetchDataForLeaderBoard(payload);
      if (res.data) {
        const data = updateCompliance(res.data);
        console.log('Updated Data:', data);
        const finalData = sortData(data);
        console.log('Sorted Data:', finalData);
        setLeaders(finalData.slice(0, 3));
        setTableData(finalData.slice(3));
      } else {
        console.log('No data returned from fetch operation');
      }
      setLoader(false);
    } catch (error) {
      console.error('Error while fetching leaderboard data', error);
      errorToast('Error while fetching leaderboard data');
    }
  };

  useEffect(() => {
    fetchleaderboard();
  }, []);

  const handlefiltertarget = async () => {
    try {
      if(!filters.week && !filters.month){
        errorToast('Please select any week or year')
      }
      setLoader(true);
      const payload = {
        flag: true,
        month: filters.month,
        week: filters.week
      }
      console.log(payload);

      const res = await AreaService.fetchDataForLeaderBoard(payload);
      if (res.data) {
        const data = updateCompliance(res.data);
        console.log('Updated Data:', data);
        const finalData = sortData(data);
        console.log('Sorted Data:', finalData);
        setfilterTable(finalData)
        setFileteredLeaders(finalData.slice(0, 3));
        setFileteredTable(finalData.slice(3));
        setLoader(false);
      } else {
        setLoader(false);
        console.log('No data returned from fetch operation');
      }
    } catch (error) {
      errorToast('Error while fetching leaderboard data')
    }
  }
  return (
    <Fragment>
      <br />
      {loader ? (
        <Loader1 />
      ) : (
        <Container fluid={true} className='pb-5'>
          <div className='d-flex align-items-center justify-content-between flex-wrap mb-3'>
            <h5 style={{ fontSize: '22px' }}>Leaderboard</h5>

            <div className='d-flex align-items-center justify-content-end flex-wrap filtersbtngroupofleader'>
              <div className='mx-1'>

                <Input type='select' value={filterType} onChange={handleFilterChange}>
                  <option value="today">Current Week</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                  {/* <option value="yearly">Yearly</option> */}
                </Input>
              </div>

              {filterType === 'weekly' && (
                <div className='mx-1'>
                  <Input type="week" value={filters.week} max={currentweek} onChange={(e) => { handleInputChange(e, 'week'); }} />
                </div>
              )}
              {filterType === 'monthly' && (
                <div className='mx-1'>
                  <Input type="month" value={filters.month} max={`${currentYear}-${currentMonth}`} onChange={(e) => { handleInputChange(e, 'month'); }} />
                </div>
              )}

              {/* {filterType === 'yearly' && (
                <div className='mx-1'>
                  <YearPicker filters={filters} setFilters={setFilters} />
                  </div>
              )} */}
              {filterType == 'today' ? '' : <Button className='btn brn-primary applyfilterbtn' color='primary' onClick={handlefiltertarget}> Apply filter</Button>}
            </div>
          </div>

          <Row> 
            {FileteredLeaders?.length > 0 ?
              FileteredLeaders.map((data, key) => (
                <Col md='6' lg='6' xl='4' key={key}>
                  <Card style={{ border: '2px solid #d9e6fc', boxShadow: 'none' }}>
                    <TopLeaderCard data={data} rank={key} />
                  </Card>
                </Col>
              ))
              :
              Leaders?.map((data, key) => (
                <Col md='6' lg='6' xl='4' key={key}>
                  <Card style={{ border: '2px solid #d9e6fc', boxShadow: 'none' }}>
                    <TopLeaderCard data={data} rank={key} />
                  </Card>
                </Col>
              ))
            }

          </Row>
          <h5>Ranks</h5>

          <Table filterTable={filterTable} filterType={filterType} data={FileteredTable.length > 0 ? FileteredTable : filterTable.length>0?FileteredTable: TableData} />
        </Container>
      )}
    </Fragment>
  );
};

export default LeaderBoard;
