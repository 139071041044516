import React from 'react';
import { Card, CardBody, CardHeader, Progress } from 'reactstrap';
import { IoIosArrowRoundDown } from "react-icons/io";
import { IoIosArrowRoundUp } from "react-icons/io";
import '../style/style.css'

const NewBarChart = ({ progressData, week, loadingForBars }) => {
    return (
        
            <Card className='progressbarParent' style={{ boxShadow: 'none' }}>
                {progressData?.map((item, key) => (
                    <CardBody className='py-2 progressbarParentChild' style={{ borderTop: '1px solid #ececec' }} key={key}>
                        <div  className='' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h5 style={{ fontSize: '16px' }}>{item.name == 'Emergency Exit' ? 'E.Exit' : item.name == 'Machine Guard' ? 'M.Guard' : item.name}</h5>
                            <div style={{ width: '50%' }}>
                                <Progress value={item.barValue} color={item.barValue >= 80 ? 'success' : item.barValue >= 50 && item.barValue < 80 ? 'warning' : item.barValue < 50 ? 'danger' : 'dark'} style={{ height: '5px' }} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* <i className="bi bi-arrow-up" style={{color: 'green', marginRight: '5px'}}></i> */}
                                {item.trend == "uptrend" ? <IoIosArrowRoundUp style={{ fontSize: '25px', color: '#54ba4a' }} /> : <IoIosArrowRoundDown style={{ fontSize: '25px', color: '#ff0000' }} />}

                                <span>{item.barValue}%</span>
                            </div>
                        </div>
                     
                            <section>
                                <div className=' d-flex justify-content-between flex-wrap'>
                                    <p className='m-0' style={{ color: '#8C8C8C', fontSize: '12px' }}>Max Alerts by: <span style={{ color: 'black' }}>{item.area_owner || 'N/A'}</span> </p>
                                    <p className='m-0' style={{ color: '#8C8C8C', fontSize: '12px' }}> Alerts: <span style={{ color: 'black' }}> {item.max_alerts || 'N/A'} </span></p>
                                </div>
                                <p className='m-0 p-0' style={{ color: '#8C8C8C', fontSize: '12px' }}> Sub Area: <span style={{ color: 'black' }}>{item.subarea_with_max_alerts || 'N/A'} </span> </p>
                            </section>
                           
                    </CardBody>
                ))}

            </Card>
       
    );
}

export default NewBarChart;
