import React from 'react'
import GlobalAllCamera from '../../../Screens/GlobalUser/CameraConfigurations/cameras_table';
// import GlobalTargetAndControls from '../../../Screens/GlobalUser/TargetsAndControls/targets_and_controls';

const ConnectedCameras = () => {
  return (
    <>
      <GlobalAllCamera area={true} /> 
    </>
  )
}

export default ConnectedCameras
