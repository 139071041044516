import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import DetailCard from "./Components/DetailsCard/Detail_Card";
import Loader1 from "../../../CommonElements/Spinner/loader";
import AreaService from "../../../api/areaService";
import { getCurrentWeekWithYear } from "../../../utils/currentWeekWithYear";

const AreaAnalysis = () => {
  const currentWeekk = getCurrentWeekWithYear();
  const [loader, setloader] = useState(true);
  const [allData, setAllData] = useState([]);
  const [areaData, setareaData] = useState([]);
  const processData = (data) => {
    // Process sub-areas to calculate total Compliance and Violations
    data.forEach((area) => {
      const subAreaCounts = {};
      const newSubAreas = [];

      area.SubAreas.forEach((sub) => {
        if (subAreaCounts[sub.SubAreaName]) {
          subAreaCounts[sub.SubAreaName].count += 1;
          subAreaCounts[sub.SubAreaName].compliance += sub.Compliance;
          subAreaCounts[sub.SubAreaName].violations += sub.Violations;
          console.log(subAreaCounts[sub.SubAreaName].violations);
        } else {
          subAreaCounts[sub.SubAreaName] = {
            count: 1,
            compliance: sub.Compliance,
            violations: sub.Violations,
          };
        }
      });

      Object.keys(subAreaCounts).forEach((subAreaName) => {
        const subAreaData = subAreaCounts[subAreaName];

        newSubAreas.push({
          SubAreaName: subAreaName,
          Cameras: subAreaData.count,
          Compliance: subAreaData.compliance, // Sum of Compliance
          Violations: subAreaData.violations, // Sum of Violations
        });
      });

      area.SubAreas = newSubAreas;
    });

    // Adjusted logic for area-level Compliance
    data.forEach((area) => {
      // Calculate total Compliance and Violations at the area level
      const totalSubAreaCompliance = area.SubAreas.reduce(
        (acc, subArea) => acc + (subArea.Compliance || 0),
        0
      );
      const totalSubAreaViolations = area.SubAreas.reduce(
        (acc, subArea) => acc + (subArea.Violations || 0),
        0
      );

      // Calculate overall area compliance using the specified formula
      const areaCompliance =
        totalSubAreaCompliance + totalSubAreaViolations > 0
          ? (totalSubAreaCompliance /
              (totalSubAreaCompliance + totalSubAreaViolations)) *
            100
          : 0; // Handle case when denominator is 0

      // area.Compliance = Math.round(areaCompliance);
      area.Compliance =
        totalSubAreaViolations == 0 ? 100 : Math.round(areaCompliance); // Add Compliance field at the area level

      // Total cameras calculation remains unchanged
      const totalCameras = area.SubAreas.reduce(
        (acc, subArea) => acc + subArea.Cameras,
        0
      );
      area.Cameras = totalCameras; // Add Cameras field at the area level
    });

    return data;
  };
  useEffect(() => {
    const payload = {
      // safety_area: area_id ? [area_id] : filters.areas ,
      // shift: filters.shifts ,
      // start_date: filters.starting!=='' ? formatDate(filters.starting) : filters.date=='' ? '' : formatDate(filters.date),
      // end_date: filters.ending!=='' ? formatDate(filters.ending) : filters.date=='' ? '' : formatDate(filters.date),
      week: currentWeekk,
    };
    getAiReportsCardsFunc(payload);
  }, []);

  // Example Usage inside useEffect

  async function getAiReportsCardsFunc(payload) {
    setloader(true);
    const res = await AreaService.getAiReportsCards(payload);

    let updatedData = res?.data;
    let mappedAreas = [];

    updatedData = updatedData?.sort((a, b) => {
      const numA = parseInt(a.AreaName.split("-")[1], 10);
      const numB = parseInt(b.AreaName.split("-")[1], 10);
      return numA - numB;
    });

    // Fetch allarea if not already populated

    const areaRes = await AreaService.getAreaUserTickets(
      JSON.parse(localStorage.getItem("userData"))?.id
    );
    if (areaRes) {
      setloader(false);
    }
    setareaData(areaRes.data.data.areas);
    mappedAreas =
      areaRes?.data?.data?.areas.map((area) => ({
        ...area,
        label: `${area.area_name}, ${area.area_owner}`,
        disabled: !area.active,
      })) || [];

    updatedData = updatedData.filter((data) =>
      mappedAreas?.some((a) => data.AreaName === a.area_name && a.active)
    );

    const updateddData = processData(updatedData); // Process the data
    console.log("Updated Data:", updateddData); // Check if the data is correct

    setAllData(updateddData); // Set the updated data to state
    // setAllData(updatedData);
  }

  return (
    <>
      <br />
      <Container fluid={true}>
        <h5>Area Analysis</h5>
        {loader ? (
          <Loader1 />
        ) : (
          <Row>
            {allData.map((items) => (
              <Col xxl="3" xl="4" lg="6" md="6">
                <DetailCard
                  allData={allData}
                  setAllData={setAllData}
                  items={items}
                  areaData={areaData}
                />
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </>
  );
};

export default AreaAnalysis;
