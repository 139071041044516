import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Input } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import Loader3 from '../../../../../../CommonElements/Spinner/loader3'
import '../style/style.css'
export default function Component({ seriess }) { 
  function getCurrentWeekdata() {
    // Create a new date object for the current date and time
    const currentDate = new Date();
    
    // Get the first day of the year
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
    
    // Calculate the number of days between the current date and the start of the year
    const pastDaysOfYear = (currentDate - startOfYear) / 86400000; // 86400000 ms per day
    
    // Calculate the current week number
    const currentWeek = Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
    
    return currentWeek;
  }

  const [currentweek, setcurrentweek] = useState(getCurrentWeekdata())
  const getCurrentWeek = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil(days / 7);
    return weekNumber;
  };
  
  const getFormattedWeek = () => {
    const currentYear = new Date().getFullYear();
    const weekNumber = getCurrentWeek();
    // Pad the week number with a leading zero if it is less than 10
    const formattedWeekNumber = weekNumber < 10 ? `0${weekNumber}` : weekNumber;
    return `${currentYear}-W${formattedWeekNumber}`;
  };
  
  const [selectedWeeks, setSelectedWeeks] = useState(getFormattedWeek());
  const [series, setSeries] = useState([0, 100]); // Initial state for the chart series

  // Update series state whenever seriess prop changes
  useEffect(() => {
    const activeValue = seriess?.[0] ?? 0; // Safely get the first element or default to 0
    setSeries([activeValue, 100 - activeValue]); // Calculate the remainder to make the total 100%
  }, [seriess]);

  // Chart options configuration
  const chartOptions = {
    chart: {
      type: 'donut',
      height: 200, // Default height for the chart
    },
    series: series,  // Use state-managed series for reactivity
    labels: ['Over all compliance', 'Inactive'],
    colors: ['#175fa4', '#dde8f9'], // First color for the active percentage, second for the filler
    plotOptions: {
      pie: {
        donut: {
          size: '60%',
          labels: {
            show: true,
            name: {
              show: false
            },
            value: {
              show: true,
              fontSize: '26px',
              fontFamily: 'Inter, sans-serif',
              fontWeight: 600,
              color: '#595959',
              formatter: function (val) {
                return val + '%'; // Show percentage on the active part only
              }
            },
            total: {
              show: true,
              label: 'Total',
              fontSize: '16px',
              color: '#595959',
              formatter: function () {
                return series[0] + '%'; // Display only the active percentage in the center
              }
            }
          }
        },
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    stroke: {
      width: 4,
      colors: ['#ffffff']
    },
    responsive: [
      {
        breakpoint: 1400,  // For screens less than 1400px width
        options: {
          chart: {
            width: '500px', 
            height: 300, // Set height to 400px for screens wider than 1400px
          },
        },
      },
      {
        breakpoint: 1024,  // For screens less than 1024px width (tablets)
        options: {
          chart: {
            width: '80%',
            height: 300, // Set height to 400px for tablets
          },
        },
      },
      {
        breakpoint: 768,  // For screens less than 768px width (mobile)
        options: {
          chart: {
            width: '100%', // Full width on mobile
            height: 300,   // Set height to 400px for mobile
          },
        },
      },
    ],
  };

  // Handler for week change input
  const handleWeekChange = (event) => {
    setSelectedWeeks(event.target.value);
  };

  return (
    <Card className='pb-0 mb-0' style={{ boxShadow: 'none' }}>
      {seriess?.length <= 0 ? (
        <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '50vh' }}>
          <Loader3 />
        </div>
      ) : (
        <>
          <CardHeader>
            <h5 className='text-center' style={{ fontSize: '20px' }}>
              Over All Compliance Score
            </h5>
          </CardHeader>

          <div className=" donutParent" >
            <ReactApexChart
              options={chartOptions}
              series={series}
              type="donut"
              height={chartOptions.chart.height}
            />
          <p className='text-center mt-2' style={{ color: '#8C8C8C' }}>
            Week {currentweek} Data
          </p>
          </div>
        </>
      )}
    </Card>
  );
}
