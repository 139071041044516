import React, { useEffect, useState, useRe, useRef,useContext } from "react";
import { Download, FileText, Mail, Send, Type } from "react-feather";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import './header.css'
import { toast } from "react-toastify";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useLocation } from 'react-router-dom';
import LiveAnalysis from '../../../assets/ExcelData/Live_Analytics.xlsx';
import CameraConfig from '../../../assets/ExcelData/Camera_Configuration.xlsx';
import LiveAlert from '../../../assets/ExcelData/Live_Alerts.xlsx'
import TargetControl from '../../../assets/ExcelData/Targets_and_Controls.xlsx'
import Reports from '../../../assets/ExcelData/Reports.xlsx'
import AreaService from "../../../api/areaService";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { errorToast, successToast } from "../../../_helper/helper";
import { PulseLoader } from 'react-spinners';
import liveAlertContext from '../../../_helper/formData/LiveAlert/LiveAlert';

const DownloadButton = ({ totalNotifications, data }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    control,
  } = useForm();
  const { liveAlertData,totalLiveAlerts } = useContext(liveAlertContext);
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false)
  const [downloadType, setDownloadType] = useState('')
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const location = useLocation();
  const dropdownRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [duration, setDuration] = useState('daily');
  const todayy = new Date().toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(todayy);
  const [currentWeek, setcurrentWeek] = useState('');
  const [currentYear, setcurrentYear] = useState('')
  const [loading, setloading] = useState(false)
  const [newFilters, setNewFilters] = useState({
    identifier: 'date',
    module: "",
    severity: "",
    shift: "",
    date: todayy,
    week: "",
    month: "",
    starting: "",
    ending: "",

  });
  const toggle = () => setModal(!modal);

  const handleDurationChange = (event) => {
    const { value } = event.target;
    setDuration(value);
    // Reset date when changing duration type
    setSelectedDate('');
  };

  const handleDateChange = (e) => {
    const { value, name } = e.target
    console.log(value, name, 'selected date')
    if (name == 'date') {
      setNewFilters({
        ...newFilters,
        identifier: name,
        date: value,
        month: '',
        week: ''
      })
    }
    else if (name == 'week') {
      setNewFilters({
        ...newFilters,
        identifier: name,
        week: value,
        date: '',
        month: ''
      })
    }
    else if (name == 'month') {
      setNewFilters({
        ...newFilters,
        identifier: name,
        month: value,
        date: '',
        week: ''

      })
    }
    setSelectedDate(value);
  };
 


  const handleSubmitEmail = (data) => {

    toast.success(`Send in ${downloadType} format to ${data.email}`);
    reset({
      email: ''  // Clear the email field after submission
    });

    setNotificationDropDown(false)

  };


  const handleClick = (type) => {
    if (type === "email") {
      setShow((prev) => !prev)
    }

    setDownloadType(type)
  }
  const handlePDFdownload = () => {
    toast.success("Downloading started"); 
    // const current=location.pathname.split('/')[2] 
    const input = document.body;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('UAM');
    });
  };

  const namesToFind = ['camera_configuration', 'global', 'reports', 'targets', 'live_alerts'];
  const formatDate = (dateStr) => {
    const [year, month, day] = dateStr.split("-");
    return `${month}/${day}/${year}`;
  };
  function createAndDownloadExcel(moduleData, overallData) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    const dataForExcel = [
      { Module: `Date: ${overallData.date}` },
      {},
      { Module: "Module", Total: "Total", "Accuracy": "Accuracy %", Correct: "Correct", Wrong: "Wrong" },
      {
        Module: 'Over all',
        Total: overallData.correct + overallData.wrong,
        Correct: overallData.correct,
        Wrong: overallData.wrong,
        "Accuracy": overallData.percentage,
      },
      {},

      // Assuming you want headers again for modules, if not, remove this line
      { Module: "Module", Total: "Total", "Accuracy": "Accuracy %" },

      // Module-specific data with empty fields for the 'Date' column
      ...moduleData.map(item => ({
        Module: item.name,
        Total: item.value,
        "Accuracy": `${item.value}%`, // Assuming 100% correctness if value > 0
      }))
    ];
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel, { skipHeader: true });
    XLSX.utils.book_append_sheet(workbook, worksheet, "Compliance Data");
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    saveAs(blob, 'live Alerts.xlsx');
  }
  const fetchdataforTECHQA = async () => {
    setloading(true)
    try {
      const payload1 = {
        safety_area: [],
        shift: [],
        start_date: newFilters.date ? formatDate(newFilters.date) : '',
        end_date: newFilters.date ? formatDate(newFilters.date) : '',
        week: newFilters.week,
        month: newFilters.month,
      };
      console.log(payload1);
      const res = await AreaService.getOperationID(payload1);
      const res2 = await AreaService.getModelAccuracyChart(payload1)

      const total = res?.data?.ids.accepted.length + res?.data?.ids.rejected.length
      const correct = res?.data?.ids.accepted.length
      const wrong = res?.data?.ids.rejected.length
      const percentage = correct / total * 100
      const firstcolumn = {
        date: selectedDate,
        correct: correct,
        wrong: wrong,
        percentage: percentage.toFixed(2)
      }
      const secondcolumn = res2.data.totalAlertsChart
      createAndDownloadExcel(secondcolumn, firstcolumn)
      setloading(false);
      successToast('Download Completed');
      toggle();
    } catch (error) {
      setloading(false);
      errorToast('Error while fetching data')
    }

  }
  const getcurrentweekORmotn = () => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const pastDays = Math.floor((now - startOfYear) / (24 * 60 * 60 * 1000));
    const weekNo = Math.ceil((pastDays + startOfYear.getDay() + 1) / 7);
    const week = `${now.getFullYear()}-W${weekNo.toString().padStart(2, '0')}`;
    const year = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}`;
    setcurrentWeek(week);
    setcurrentYear(year);
    console.log(week, year, 'current year and month')

  }
  function creaExelForLiveAlertsFactory(liveAlertData) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    
    // Define headers and format data for Excel without the 'image' field
    const headers = {
        operation_safety_id: "Operation Safety ID",
        VioSeverity: "Violation Severity",
        module: "Module",
        violation: "Violation",
        violationArea: "Violation Area",
        camera_name: "Camera Name",
        date: "Date",
        time: "Time",
        shift: "Shift",
        camera_id: "Camera ID"
    };

    // Transform liveAlertData to include only necessary fields and headers
    const dataForExcel = liveAlertData.map(alert => ({
        operation_safety_id: alert.operation_safety_id,
        VioSeverity: alert.VioSeverity,
        module: alert.module,
        violation: alert.violation,
        violationArea: alert.violationArea,
        camera_name: alert.camera_name,
        date: alert.date,
        time: alert.time,
        shift: alert.shift,
        camera_id: alert.camera_id,
    }));

    // Prepend headers to data
    dataForExcel.unshift(headers);

    // Create worksheet and add the data
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel, { skipHeader: true });
    XLSX.utils.book_append_sheet(workbook, worksheet, "LiveAlertData");

    // Write workbook to a buffer
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a blob for download
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    // Use saveAs to trigger the download
    saveAs(blob, 'LiveAlerts.xlsx');
}
  const getLiveAlertDataforFactory=async()=>{
    if(liveAlertData){
      creaExelForLiveAlertsFactory(liveAlertData);
      successToast('Excel file downloaded.')
    }
    else{
      errorToast('Empty data response')
    }
  }
  const handleExcelDownload = () => {
    const current = location.pathname.split('/');
    const currentRoute = current.find(element => namesToFind.includes(element.toLowerCase())) || '';
    console.log(current, 'current route');

    // Check for 'default' and 'qa' conditions
    if (current.includes('default') && current.includes('qa')) {
      toggle();
      getcurrentweekORmotn();
    }
    else if(current.includes('live_alerts') && current.includes('global')){
      // alert('hello live alert');
      getLiveAlertDataforFactory()
      return
    }
    else if(current.includes('leaderboard')){
      errorToast('We are sorry, This feature is not for Leaderboard');
      return
    }
     else {
      // Mapping for routes and their corresponding download details
      const downloadMap = {
        global: { url: LiveAnalysis, fileName: 'LiveAnalytics' },
        reports: { url: Reports, fileName: 'Reports.xlsx' },
        targets: { url: TargetControl, fileName: 'TargetandControl.xlsx' },
        // live_alerts: { url: LiveAlert, fileName: 'LiveAlert.xlsx' },
        camera_configuration: { url: CameraConfig, fileName: 'CameraConfiguration.xlsx' }
      };

      // Generic function to create and trigger a download
      const triggerDownload = (url, fileName) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      if (currentRoute) {
        const downloadInfo = downloadMap[currentRoute];
        if (downloadInfo) {
           toast.success("Downloading started")
          triggerDownload(downloadInfo.url, downloadInfo.fileName);
        } else {
          errorToast('Error: Route configuration not found for download');
        }
      } else {
        errorToast('Error: No valid route found for download');
      }
    }
  };

  useEffect(() => {
    function handleOutsideClick(e) {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setNotificationDropDown(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [dropdownRef])
useEffect(() => {
   if(modal==false){
    setNewFilters({
      ...newFilters,
      date: todayy,
      month:'',
      week:'',
    });
    setDuration('daily');
    setSelectedDate(todayy)
   }
}, [modal])
console.log(notificationDropDown)
  return (
    <li className="onhover-dropdown"

    >
      <div
        className="notification-box"
        onClick={() => setNotificationDropDown(!notificationDropDown)}
      >
        <Download size={20} />
        <span className="badge rounded-pill badge-secondary">
          {totalNotifications}
        </span>
      </div>
      <div
        ref={dropdownRef}
        className={`notification-dropdown onhover-show-div ${notificationDropDown ? "active showForSelectEmail" : ""
          }`
        }
      >
        <h6 className="f-18 mb-0 dropdown-title">Download Options</h6>
        <ul>
          <li onClick={handlePDFdownload} className="b-l-primary border-4">
            <p>
              Download PDF{" "}
              <span>
                <Type color={"#1e67d6"} size={18} />
              </span>
            </p>
          </li>
          <li onClick={handleExcelDownload} className="b-l-success border-4">
            <p>
              Download Excel
              <span className="font-success">
                <FileText color={"#1e67d6"} size={18} />
              </span>
            </p>
          </li>
          <li onClick={() => handleClick('email')} className="b-l-danger border-4">
            <p>
              Send as Email
              <span>
                <Mail color={"#1e67d6"} size={18} />
              </span>
            </p>
          </li>
          {show && <form className="mt-3" onSubmit={handleSubmit(handleSubmitEmail)}>

            {/* <Label for="email">Email</Label>
                                <Input type="email" name="email" id="email" value={formData.email} onChange={handleInputChange} /> */}
            <Label className="text-start" for="email">Email</Label>
            <FormGroup className="d-flex justify-content-between gap-2">
              <Controller
                name="email"
                defaultValue=""
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Invalid email format",
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="email"
                    placeholder="Enter your email"
                    onClick={() => setNotificationDropDown(true)}
                  />
                )}
              />

              <Button color="light" className="download-btn-noti">
                <Send color='#ffffff' style={{ color: 'white' }} className="send-btn-noti" />
              </Button>

            </FormGroup>
            {errors.email && (
              <span className="text-danger">{errors.email.message}</span>
            )}

          </form>
          }
        </ul>

        {/* modal */}
        <div>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Download Accuracy Report</ModalHeader>
            <ModalBody style={{ paddingInline: '25px' }}>
              <FormGroup>
                <Label for="durationSelect">Choose a duration:</Label>
                <Input type="select" name="duration" id="durationSelect" value={duration} onChange={handleDurationChange}>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </Input>
              </FormGroup>
              {duration === 'daily' && (
                <Input
                  type="date"
                  name="date"
                  value={selectedDate}
                  max={todayy}
                  onChange={(e) => handleDateChange(e, 'daily')}
                />
              )}
              {duration === 'weekly' && (
                <Input
                  type="week"
                  name="week"
                  max={currentWeek}
                  value={selectedDate}
                  onChange={(e) => handleDateChange(e, 'weekly')}
                />
              )}
              {duration === 'monthly' && (
                <Input
                  type="month"
                  name="month"
                  max={currentYear}
                  value={selectedDate}
                  onChange={(e) => handleDateChange(e, 'monthly')}
                />
              )}
            </ModalBody>
            <ModalFooter>
              {loading ? <PulseLoader /> : <>
                <Button color="primary" onClick={() => { fetchdataforTECHQA(); }}>Download</Button>{' '}
                <Button color="secondary" onClick={() => {
                  setSelectedDate(todayy);
                  toggle();
                }}>Cancel</Button>
              </>}
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </li>
  );
};

export default DownloadButton;
