import React from 'react'
import { H2 } from '../../../../AbstractElements'
import ActivityMonitor from '../../Super Admin Dashboard/Pages/Activity_Monitor'
import AIModelAndReportsScreen from '../../../Screens/GlobalUser/AIModelReports/model_and_reports'

const Index = () => {
  return (
    <>
    {/* <ActivityMonitor /> */}
    <AIModelAndReportsScreen />
  </>
  )
}

export default Index
