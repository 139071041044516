import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap"
import ReactApexChart from "react-apexcharts";
import '../style/style.css'
import { TbPoint } from "react-icons/tb";
import Loader3 from '../../../../../../CommonElements/Spinner/loader3'

export default function Component({heatmapData,moduleLength}) {
  const [loader, setloader] = useState(true) 
  const [series, setseries] = useState([
    {
      name: 'Vest',
      data: [201, 20, 114, 259, 34, 350, 248, 743, 9]
    },
    {
      name: 'Helmet',
      data: [142, 18, 140, 238, 29, 626, 160, 695, 6]
    },
    {
      name: 'Machine Guard',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    {
      name: 'Emergency Exit',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    {
      name: 'MMHE',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
    },


  ])

  const options = {
    chart: {
      type: 'heatmap',
      width: '100%',  // Responsive width
      toolbar: {
        show: false
      },
      background: '#fff',
      fontFamily: 'inherit',
      // Add responsive array to handle specific configurations under 800px
      responsive: [{
        breakpoint: 800,
        options: {
          chart: {
            width: 600  // Set minimum width at this breakpoint
          },
          xaxis: {
            labels: {
              style: {
                fontSize: '10px'  // Reduce font size for labels under 800px
              }
            }
          },
          yaxis: {
            labels: {
              style: {
                fontSize: '10px'  // Reduce font size for labels under 800px
              }
            }
          },
          dataLabels: {
            style: {
              fontSize: '10px'  // Reduce font size for data labels under 800px
            }
          }
        }
      }]
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        fontWeight: 100,
        colors: ['#1e293b'],
         
      }
    },
    xaxis: {
        categories: heatmapData.areas.map((area, index) => {
          const label = `${area}, ${heatmapData.areaOwner[index]}`;
          return label.length > 10 ? `${label.substring(0, 10)}...` : label;
        }),
      // categories: heatmapData.areas.map((area, index) => `${area} (${heatmapData.areaOwner[index]})`),
      // categories: [
      //   'AO-02 Aftab',
      //   'AO-03 Arsalan',
      //   'AO-06 Meraj',
      //   'AO-07 Moa..',
      //   'AO-08 Shah',
      //   'AO-10 Nazir',
      //   'AO-12 Shafiq',
      //   'AO-14 Shah',
      //   'AO-14 Sheraz'
      // ],
      labels: {
        style: {
          fontSize: '12px'
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    
   
    yaxis: {
      // categories: ['Vest', 'Helmet'],
      categories: heatmapData.data.map(item => item.name), 
      labels: {
        style: {
          fontSize: '12px',
        }
      }
    },
    plotOptions: {
      heatmap: {
        enableShades: false,
        shadeIntensity: 0.5,
        distributed: true,
        radius: 10,
        padding: 5,
        colorScale: {
          ranges: [
            // Ranges omitted for brevity; include as previously defined
            {
              from: -1,
              to: 0,
              name: ' ',
              color: '#f6f6f6',
              foreColor: '#f6f6f6'
            },
            {
              from: 1,
              to: 10,
              name: 'Normal',
              color: '#dde8f9',
              foreColor: '#000000'
            },
            {
              from: 11,
              to: 100,
              name: 'Serious',
              color: '#86ADE9',
              foreColor: '#000000'
            },
            {
              from: 101,
              to: 300,
              name: 'Trouble',
              color: '#1e67d6',
              foreColor: '#ffffff',
            },
            {
              from: 301,
              to: 1000,
              name: 'Critical',
              color: '#164b9c',
              foreColor: '#ffffff'
            }
          ]
        }
      }
    },
    grid: {
      show: false
    },
    legend: {
      show: false
    },
    tooltip: {
      enabled: true,
      theme: 'light',
      x: {
        show: true
      },
      y: {
        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
          const areaName = heatmapData?.areaOwner[dataPointIndex];
          return `${value} <span  className='m-0 p-0' style={{fontSize:'10px',fontWeight:'lighter'}} >
          alerts</span> 
          , ${areaName}`; 
        }
      }
    }
  };
  
  const items = [
    { label: 'Normal', color: '#dde8f9', width: '74px', height: '5px', borderRadius: '5px' },
    { label: 'Serious', color: '#86ADE9', width: '58px', height: '5px', borderRadius: '5px' },
    { label: 'Trouble', color: '#1e67d6', width: '42px', height: '5px', borderRadius: '5px' },
    { label: 'Critical', color: '#164b9c', width: '26px', height: '5px', borderRadius: '5px' },
    { label: 'N/A', color: '#f6f6f6', width: '18px', height: '5px', borderRadius: '5px', padding: true },
  ];

  useEffect(() => {
    if(heatmapData.data.length >0 ){
      setseries(heatmapData.data);
      setloader(false);
    }
  }, [heatmapData])
  
  return (
    <Card>

      <CardHeader className='pb-0' style={{ border: '0px' }}>
        <h5 >Area Intensity Heatmap</h5>
      </CardHeader>
      {loader?<div className='py-3'><Loader3/></div>:
      <CardBody className='pt-0'>
        <Row >
          <Col >
            <div className='colorShadeOfheatMap' style={{ display: 'flex', flexDirection: 'column', gap: '10px', minWidth:'205px' }}>
              
              {items.map((item, index) => (
                <div   key={index} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <TbPoint style={{ color: item.color, fontSize: '25px' }} />
                  <span style={{ fontSize: '14px', color: '#6c757d' }}>{item.label}</span>
                  <div style={{
                    width: item.width,
                    height: item.height,
                    borderRadius: item.borderRadius,
                    backgroundColor: item.color,
                    marginLeft: item.padding == true && '23px'
                  }}
                  className='barcolorwidth'
                  >
                    
                  </div>

                </div>
              ))}
            </div>
          </Col>
          <Col xs='12' className='heatmapnewdesign' style={{ overflowX: 'auto' }}>
  <div style={{ minWidth: '600px' }}>
    <ReactApexChart
      options={options}
      series={series}
      type="heatmap"
      height="340px"
    />
    <p className='text-center'>Alerts</p>
  </div>
</Col>

        </Row>
      </CardBody>
        }

    </Card>
  )
}