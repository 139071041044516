import React from "react";
import Routers from "./Route";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import GalleryProvider from "./_helper/Gallery/GalleryProvider";
import PopupStateProvider from "./_helper/popupState/PopupStateProvider";
import FormDataProvider from "./_helper/formData/formDataProvider";
import ResyncProvider from "./_helper/resync/ResyncProvider";
import LiveAlertProvider from "./_helper/formData/LiveAlert/LiveAlertProvider";

const App = () => (
  <div className="App">
    <CustomizerProvider>
      <GalleryProvider>
      <AnimationThemeProvider>
        <FormDataProvider>
          <LiveAlertProvider>
        <PopupStateProvider>
          <ResyncProvider>
        <Routers />
        </ResyncProvider>
        </PopupStateProvider>
          </LiveAlertProvider>
        </FormDataProvider>
      </AnimationThemeProvider>
      </GalleryProvider>
    </CustomizerProvider>
  </div>
);

export default App;
