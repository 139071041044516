import React from 'react'
import DefautlDashboard from '../../Dashboard/Default/index'
import LiveAnalyticsScreen from '../../../Screens/GlobalUser/LiveAnalytics/live_analytics'
import QADash from './QADash'
const Index = () => {
  return (
    <>
    {/* <DefautlDashboard /> */}
    {/* <LiveAnalyticsScreen /> */}
    <QADash />
    </>
  )
}

export default Index
