import React from 'react'
import LiveCamera from '../../GlobalDashboard/liveCamera/liveCamera'

const Index = () => {
  return (
    <>
   <LiveCamera />
  </>

  )
}

export default Index
