import React, { createContext, useState } from "react"
import CameraImage from "../assets/images/cameras/camera.jpeg"

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const[Cameras, setCameras]= useState([
    // {
    //   active: true,
    //   cameraIP: "192.168.10.201",
    //   area: "SA-1",
    //   subArea: "Roof Tops (Palletizing)",
    //   cameraId: "ICF-SA1-RT-1-4233-213",
    //   cameraName: "Ezviz",
    //   date: "2024-10-10",
    //   time: "02:56 AM",
    //   module: "Helmet",
    //   gate: 1,
    //   roomName: "Ezviz",
    //   image_url: CameraImage,
    // },
    // {
    //   active: false,
    //   cameraIP: "192.168.11.220",
    //   area: "SA-1",
    //   subArea: "Palletizing 1,2 & 3",
    //   cameraName: "Ezviz",
    //   date: "2024-10-10",
    //   time: "03:23 AM",
    //   cameraId: "ICF-SA1-P-1-NVR-IP",
    //   gate: 2,
    //   roomName: "Ezviz",
    //   module: "Helmet",
    //   image_url: CameraImage,
    // },
    // {
    //   active: true,
    //   cameraIP: "192.167.09.220",
    //   area: "SA-1",
    //   subArea: "Palletizing Office",
    //   cameraName: "Ezviz",
    //   date: "2024-10-10",
    //   time: "04:56 AM",
    //   module: "Helmet",
    //   cameraId: "ICF-SA1-P-1-NVR-IP",
    //   gate: 3,
    //   roomName: "Ezviz",
    //   image_url: CameraImage,
    // },
    // {
    //   active: true,
    //   cameraIP: "192.165.10.12",
    //   area: "SA-1",
    //   subArea: "Waste Window Area",
    //   cameraName: "Ezviz",
    //   date: "2024-10-10",
    //   module: "Helmet",
    //   time: "05:01 AM",
    //   cameraId: "ICF-SA1-P-1-NVR-IP",
    //   gate: 4,
    //   roomName: "Ezviz",
    //   image_url: CameraImage,
    // },
    // {
    //   active: true,
    //   area: "SA-2",
    //   cameraIP: "192.168.10.213",
    //   subArea: "Ammonia Compressor Room",
    //   cameraId: "ICF-SA2-ACR-1-05-213 ",
    //   cameraName: "Ezviz",
    //   module: "Helmet",
    //   roomName: "Ezviz",
    //   gate: 5,
    //   date: "2024-10-10",
    //   time: "05:25 AM",
    //   image_url: CameraImage,
    // },
    // {
    //   active: true,
    //   area: "SA-2",
    //   cameraIP: "192.168.10.220",
    //   subArea: "Ammonia Compressor Room",
    //   cameraName: "Ezviz",
    //   roomName: "Ezviz",
    //   module: "Helmet",
    //   gate: 6,
    //   date: "2024-10-10",
    //   time: "06:51 AM",
    //   cameraId: "ICF-SA2-ACR-2-5-220",
    //   image_url: CameraImage,
    // },
    // {
    //   active: false,
    //   area: "SA-2",
    //   cameraIP: "192.168.10.221",
    //   subArea: "Ammonia Compressor Room",
    //   cameraName: "Ezviz",
    //   roomName: "Ezviz",
    //   gate: 7,
    //   module: "Helmet",
    //   date: "2024-10-10",
    //   time: "07:12 AM",
    //   cameraId: "ICF-SA2-ACR-3-5-221",
    //   image_url: CameraImage,
    // },
    // {
    //   active: true,
    //   cameraIP: "192.168.10.222",
    //   subArea: "Ammonia Compressor Room",
    //   cameraName: "Ezviz",
    //   area: "SA-2",
    //   roomName: "Ezviz",
    //   module: "Helmet",
    //   gate: 8,
    //   date: "2024-10-10",
    //   time: "08:56 AM",
    //   cameraId: "ICF-SA2-ACR-4-5-222",
    //   image_url: CameraImage,
    // },
    // {
    //   active: false,
    //   cameraIP: "192.168.10.223",
    //   area: "SA-2",
    //   subArea: "Ammonia Compressor Room",
    //   cameraName: "Ezviz",
    //   date: "2024-10-10",
    //   module: "Helmet",
    //   roomName: "Ezviz",
    //   gate: 9,
    //   time: "08:58 AM",
    //   cameraId: "ICF-SA2-ACR-5-5-223",
    //   image_url: CameraImage,
    // },
    // {
    //   active: true,
    //   area: "SA-2",
    //   cameraIP: "192.168.10.225",
    //   subArea: "Ammonia Compressor Room",
    //   cameraName: "Ezviz",
    //   date: "2024-10-10",
    //   time: "09:33 AM",
    //   module: "Helmet",
    //   roomName: "Ezviz",
    //   gate: 10,
    //   cameraId: "ICF-SA2-ACR-6-5-225",
    //   image_url: CameraImage,
    // },
    // {
    //   active: true,
    //   area: "SA-2",
    //   cameraIP: "192.168.10.226",
    //   subArea: "Ammonia Compressor Room",
    //   cameraName: "Ezviz",
    //   module: "Helmet",
    //   date: "2024-10-10",
    //   time: "09:56 AM",
    //   roomName: "Ezviz",
    //   gate: 11,
    //   cameraId: "ICF-SA2-ACR-7-5-226",
    //   image_url: CameraImage,
    // },
    // {
    //   active: false,
    //   area: "SA-2",
    //   cameraIP: "192.168.10.81",
    //   subArea: "Ref workshop",
    //   cameraName: "Ezviz",
    //   date: "2024-10-10",
    //   module: "Helmet",
    //   time: "10:10 AM",
    //   roomName: "Ezviz",
    //   gate: 12,
    //   cameraId: "ICF-SA2-RW-1-2-81",
    //   image_url: CameraImage,
    // },
    // {
    //   active: false,
    //   area: "SA-2",
    //   cameraIP: "192.168.9.52",
    //   subArea: "Ref workshop",
    //   cameraName: "Ezviz",
    //   date: "2024-10-10",
    //   time: "10:20 AM",
    //   module: "Helmet",
    //   cameraId: "ICF-SA2-RW-2-8-52",
    //   roomName: "Ezviz",
    //   image_url: CameraImage,
    //   gate: 13,
    // },
    // {
    //   active: true,
    //   area: "SA-2",
    //   cameraIP: "192.168.10.79",
    //   subArea: "Ref Control Room",
    //   cameraName: "Ezviz",
    //   date: "2024-10-10",
    //   time: "10:25 AM",
    //   module: "Helmet",
    //   cameraId: "ICF-SA2-RCR-1-2-79",
    //   roomName: "Ezviz",
    //   gate: 14,
    //   image_url: CameraImage,
    // },
    // {
    //   active: true,
    //   area: "SA-2",
    //   cameraIP: "192.168.9.51",
    //   subArea: "Ref Control Room",
    //   cameraName: "Ezviz",
    //   date: "2024-10-10",
    //   module: "Helmet",
    //   time: "10:56 AM",
    //   cameraId: "ICF-SA2-RCR-2-8-51",
    //   roomName: "Ezviz",
    //   gate: 14,
    //   image_url: CameraImage,
    // },
    // {
    //   active: true,
    //   area: "SA-3",
    //   cameraIP: "192.168.9.51",
    //   subArea: "Admin Building Roof Top",
    //   cameraName: "Ezviz",
    //   date: "2024-10-10",
    //   module: "Emergency Exit",
    //   time: "10:56 AM",
    //   cameraId: "ICF-SA2-RCR-2-8-51",
    //   roomName: "Ezviz",
    //   gate: 15,
    //   image_url: CameraImage,
    // },
    // {
    //   active: true,
    //   area: "SA-3",
    //   cameraIP: "192.168.9.51",
    //   subArea: "AHU Room above Canteen",
    //   cameraName: "Ezviz",
    //   date: "2024-10-11",
    //   module: "Emergency Exit",
    //   time: "10:56 AM",
    //   cameraId: "ICF-SA2-RCR-2-8-51",
    //   roomName: "Ezviz",
    //   gate: 16,
    //   image_url: CameraImage,
    // },
    // {
    //   active: true,
    //   area: "SA-3",
    //   cameraIP: "192.168.9.51",
    //   subArea: "Main Asset scrap yard",
    //   cameraName: "Ezviz",
    //   date: "2024-10-12",
    //   module: "Emergency Exit",
    //   time: "12:56 AM",
    //   cameraId: "ICF-SA2-RCR-2-8-51",
    //   roomName: "Ezviz",
    //   gate: 17,
    //   image_url: CameraImage,
    // },
  ])
 

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        Cameras,
        setCameras
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
