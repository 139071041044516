import React from 'react'
import ItSupport from '../../Dashboard/itSupport/';

const Index = () => {
  return (
    <>
    <ItSupport />
    </>
  )
}

export default Index
