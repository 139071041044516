import api from './api'

const CameraService = {

    getAllLiveCameras () {
        return api.get(`cameras/get_all_live_cameras`)
    },
    getAllUserCameras (user_id) {
        return api.get(`cameras/get_all_cameras/${user_id}`)
    },
    addCamera (payload) {
        return api.post(`cameras/add_camera`,payload)
    },
    getCameraDropdowns () {
        return api.get(`cameras/camera_dropdowns`)
    },
    addCamerasWithExcel (payload) {
        return api.post(`cameras/add_cameras_with_excel`,payload)
    },
    deleteCamera (camera_id) {
        return api.delete(`cameras/delete_camera/${camera_id}`)
    },
    getCameraByID (payload) {
        return api.put(`cameras/get_by_camera_id`, payload)
    },
    updateCamera(payload){
        return api.post(`cameras/update_camera`, payload)
    },
    updateCameraStatus(id){
        return api.put(`cameras/update_camera_status/${id}`)
    }

}

export default CameraService;