import api from './api'

const itService = {
    activityLogs () {
        return api.get(`users/get_activity_log`)
    },
    getLogs () {
        return api.get(`users/get_user_logs`)
    }
   
}

export default itService;