import React from 'react'
import ItDashboard from '../../ItDashboard/It Officer/Pages/ItDashboard'
import SuperDashboard from './Dashboard'

const Dashboardd = () => {
  return (
    <>
      <ItDashboard typee={'super'} />
    </>
  )
}

export default Dashboardd
