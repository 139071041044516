import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import AccuracyCardDesign from './Cards/AccuracyCardDesign';
import Connectivitycard from './Cards/Connectivitycard';
import HighSeverityCard from './Cards/HighSeverityCard';

const HeroSection = ({data,severityAlertsData,width}) => { 
    const [accuracy, setaccuracy] = useState(data);
    const [maxalert, setmaxalert] = useState(severityAlertsData)

useEffect(() => { 
    setaccuracy(data);
    setmaxalert(severityAlertsData)

}, [data,severityAlertsData])
    
    return (
        <>
       
            {/* <Col xxl='3' xl='6'>
                <AccuracyCardDesign data={accuracy}/>
            </Col>
            <Col xxl='3' xl='6'>
                <Connectivitycard data={accuracy.connectivity} />
            </Col>
            <Col xxl='6' xl='12'>
                <HighSeverityCard data={maxalert}  />
            </Col> */}
                   
                    <Col xxl='3' xs='6'>
                        <Connectivitycard data={accuracy.connectivity} />
                    </Col>
                  
                     <Col xxl='3' xs='6'>
                        <AccuracyCardDesign data={accuracy}/>
                    </Col>
                        <Col md='6' xs='12' >
                        <HighSeverityCard data={maxalert}  />
                    </Col>
                    
                     
                        
                    {/* <Col xxl='4' xs='6'>
                        <HighSeverityCard data={maxalert}  />
                    </Col>
                     <Col xxl='4' xs='6'>
                        <AccuracyCardDesign data={accuracy}/>
                    </Col>
                    */}
        
        </>
    );
}

export default HeroSection;
