import React from 'react'
import DefaultDashboard from '../../GlobalDashboard/Default/index';

const FactoryDashboard = () => {
  return (
    <div>
      <DefaultDashboard />
    </div>
  )
}

export default FactoryDashboard
