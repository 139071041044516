import React from 'react'
import FactoryContactusPage from '../../CompanyDashbaord/Pages/factoryContactusPage'

const ContactSupport = () => {
  return (
    <>
      <FactoryContactusPage />
    </>
  )
}

export default ContactSupport
