import React from 'react'
import Logs from '../../Super Admin Dashboard/Pages/Logs'

const Index = () => {
  return (
    <>
    <Logs />
  </>
  )
}

export default Index
