import api from "./api"


export const analyticsPageService = {
    alertsTrend(payload) {
        return api.put(`alert_trend_live_analytics/alert_trend_live_analytics`, payload);
    },
    highSevrityF() {
        return api.put(`get_high_severity/get_high_severity`)
    }
}